import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {MediaType, Pageable, ProductFilter} from '@app/_shared';

@Injectable({providedIn: 'root'})
export class ProductService {

  constructor(private http: HttpClient) {
  }

  /**
   * Filter products
   * @param storeId
   * @param filter
   * @param pageable
   */
  public filterProducts(filter: ProductFilter, pageable: Pageable): Observable<any> {
    let url = `${environment.productApiUrl}/products/filter`;
    let params = pageable ? pageable.getParams() : new HttpParams();
    if (filter?.text) {
      params = params.append('text', filter.text);
    }

    if (filter?.available === true) {
      params = params.append('available', true);
    }

    if (filter?.available === false) {
      params = params.append('available', false);
    }

    if (filter?.period) {
      params = params.append('period', '' + filter.period);
    }

    if (filter?.archived) {
      params = params.append('archived', true);
    }

    filter.collections?.forEach(col => {
      params = params.append('collections', col.id);
    });

    filter.brands?.forEach(brand => {
      params = params.append('brands', brand.id);
    });

    filter.suppliers?.forEach(supplier => {
      params = params.append('suppliers', supplier.id);
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);

  }

  /**
   * Filter inventory products
   * @param filter
   * @param pageable
   */
  public filterInventoryProducts(filter: string, pageable: Pageable): Observable<any> {
    let url = `${environment.productApiUrl}/products/inventory`;
    let params = pageable ? pageable.getParams() : new HttpParams();
    if (filter) {
      params = params.append('filter', filter);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);

  }

  /**
   * Update inventory
   * @param productId
   * @param variationId
   * @param quantity
   */
  public updateInventory(productId: string, variationId: string, quantity: number): Observable<any> {
    let url = `${environment.productApiUrl}/products/inventory/${productId}`;
    let params = new HttpParams();
    if (variationId) {
      params = params.append('variationId', variationId);
    }
    params = params.append('quantity', quantity);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.patch(url, null, httpOptions);

  }


  public getDetailById(id: string): Observable<any> {
    const url = `${environment.productApiUrl}/products/detail/${id}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .get(url, httpOptions);
  }

  public importAliExpressProduct(productUrl: string): Observable<any> {
    const url = `${environment.productApiUrl}/products/import/aliexpress`;

    let params = new HttpParams();
    params = params.append('productUrl', productUrl);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.get(url, httpOptions);
  }

  public importShopifyProduct(urlProduct: string): Observable<any> {
    const url = `${environment.productApiUrl}/products/import/shopify`;

    let params = new HttpParams();
    params = params.append('url', urlProduct);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.get(url, httpOptions);
  }


  public handleError(error: any) {
    return throwError(error);
  }

  /**
   * Delete products
   * @param ids
   */
  public deleteProducts(ids: string[]): Observable<any> {
    let url = `${environment.productApiUrl}/products`;
    let params = new HttpParams();
    params = params.append('ids', ids.join(','));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.delete(url, httpOptions);
  }

  /**
   * Enable/Disable products
   * @param ids
   */
  public updateAvailability(ids: string[], availability: boolean): Observable<any> {
    let url = `${environment.productApiUrl}/products/availability`;
    let params = new HttpParams();
    params = params.append('ids', ids.join(','));
    params = params.append('availability', availability);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.patch(url, null, httpOptions);
  }

  public duplicate(id: string): Observable<any> {
    let url = `${environment.productApiUrl}/products/duplicate/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'text' as 'json',
    };
    return this.http.post(url, null, httpOptions);
  }


  /**
   * Archive products
   * @param ids
   */
  public updateArchiveStatus(ids: string[], archived: boolean): Observable<any> {
    let url = `${environment.productApiUrl}/products/archive`;
    let params = new HttpParams();
    params = params.append('ids', ids.join(','));
    params = params.append('archived', archived);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };


    return this.http.patch(url, null, httpOptions);
  }

  /**
   * Filter customers to show in modals
   * @param filter
   * @param pageable
   */
  public filterShortListProducts(filter: string, pageable: Pageable): Observable<any> {
    let url = `${environment.productApiUrl}/products/shortList`;

    let params = pageable ? pageable.getParams() : new HttpParams();
    if (filter) {
      params = params.append('searchText', filter);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * Filter customers to show in modals
   * @param filter
   * @param pageable
   */
  public filterProductsForAnalytics(filter: string, pageable: Pageable): Observable<any> {
    let url = `${environment.productApiUrl}/products/shortList-analytics`;


    let params = pageable ? pageable.getParams() : new HttpParams();
    if (filter) {
      params = params.append('searchText', filter);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * Export products
   * @param ids
   */
  public exportProducts(ids: string[]): Observable<any> {
    let url = `${environment.productApiUrl}/products/export`;
    let params = new HttpParams();
    params = params.append('ids', ids.join(','));

    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: params
    });
  }

  /**
   * Get products by ids
   * @param ids
   */
  public getByIds(ids: string[]): Observable<any> {
    let url = `${environment.productApiUrl}/products/by-ids`;
    let params = new HttpParams();
    params = params.append('ids', ids.join(','));

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * count products
   */
  public countProducts(): Observable<any> {
    let url = `${environment.productApiUrl}/products/count`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * count products
   */
  public countProductsOverview(): Observable<any> {
    let url = `${environment.productApiUrl}/products/overview-count`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }


  /**
   * Filter product ad copies
   * @param productId
   * @param pageable
   */
  public filterProductAdCopies(productId: string, pageable: Pageable): Observable<any> {
    let url = `${environment.productApiUrl}/product-openai/ad-copy/${productId}`;
    let params = pageable ? pageable.getParams() : new HttpParams();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);

  }

  getTopProducts(): Observable<any> {
    let url = `${environment.productApiUrl}/products/top`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    return this.http.get(url, httpOptions);
  }

  uploadImage(file: File): Observable<Object> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    let url = `${environment.productApiUrl}/media/upload?type=` + MediaType.PRODUCT;
    const httpOptions = {
      responseType: 'text' as 'json',
    };
    return this.http.post(url, formData, httpOptions);
  }

  /**
   * Check slug availability
   * @param slug
   */
  public checkProductSlugAvailability(slug: string): Observable<any> {
    let url = `${environment.productApiUrl}/products/check/${slug}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * Check import products status
   */
  public checkProductsImportStatus(): Observable<any> {
    let url = `${environment.productApiUrl}/products/import-status`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  markAsSeenImportStatus(): Observable<any> {
    let url = `${environment.productApiUrl}/products/import-status-seen`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.put(url, null, httpOptions);
  }

  retryShopifyImport(): Observable<any> {
    let url = `${environment.productApiUrl}/products/import-shopify`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, null, httpOptions);
  }
}

