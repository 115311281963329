import {ApplicationType} from "@app/_shared";

export function getYoutubeVideoId(url: string) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11)
    ? match[2]
    : null;
}

/**
 * Get error message from the back response
 * @param response
 * @param defaultMessage
 */
export function extractErrorMessagesFromResponse(response: any, defaultMessage?: string) {
  let messages = '';
  let error;
  try {
    error = JSON.parse(response.error);
  } catch (e) {
    error = response.error;
  }
  const errorMessages = error?.errors;
  if (errorMessages && errorMessages.length > 0) {
    try {
      errorMessages.forEach((item: any) => {
        messages += '- ' + item + '<br/>';
      });
    } catch {
      messages += errorMessages;
    }
  } else if (defaultMessage) {
    messages = defaultMessage;
  }
  return messages;
}


export function genUniqueId(): string {
  const dateStr = Date
    .now()
    .toString(36); // convert num to base 36 and stringify

  const randomStr = Math
    .random()
    .toString(36)
    .substring(2, 8); // start at index 2 to skip decimal point

  return `${dateStr}-${randomStr}`;

}

export function getStoreId(): string {
  return window.location.pathname.split(';')[0]?.split('/')[2];
}

export function getAmountFormat(number: number) {
  if (!number || number == 0) {
    return '0';
  } else {
    // hundreds
    if (number <= 999) {
      return +number.toFixed(2) + '';
    }
    // thousands
    else if (number >= 1000 && number <= 999999) {
      return +(number / 1000).toFixed(2) + 'K';
    }
    // millions
    else if (number >= 1000000 && number <= 999999999) {
      return +(number / 1000000).toFixed(2) + 'M';
    }
    // billions
    else if (number >= 1000000000 && number <= 999999999999) {
      return +(number / 1000000000).toFixed(2) + 'B';
    } else
      return +number.toFixed(2) + '';
  }
}

export function getFirstAvailableUrlForEmployee(items: any, features: any, firstAvailableUrl: any) {
  const firstAvailableFeature = features.find((fe: any) => fe.actionFeatures.includes("READ")).feature;
  const menuItem = items.filter((menuItem: any) => menuItem.feature == firstAvailableFeature);
  if (menuItem.length > 0) {
    firstAvailableUrl = menuItem[0].url;
  } else {
    const menuItems = items.map((value: any) => value.subMenus).reduce((acc: any, val: any) => acc.concat(val), []);
    firstAvailableUrl = menuItems.filter((item: any) => item?.feature == firstAvailableFeature)[0]?.url;
  }
  return firstAvailableUrl;
}


export function getApplicationSettingURL(applicationType: ApplicationType): string {
  switch (applicationType) {
    case ApplicationType.SALES_POP:
      return '/store/' + getStoreId() + '/applications/setting/sales-pop';
    case ApplicationType.COUNTDOWN:
      return '/store/' + getStoreId() + '/applications/setting/countdown';
    case ApplicationType.FACEBOOK_PIXEL:
      return '/store/' + getStoreId() + '/applications/setting/facebook';
    case ApplicationType.SNAPCHAT_PIXEL:
      return '/store/' + getStoreId() + '/applications/setting/snapchat';
    case ApplicationType.TIKTOK_PIXEL:
      return '/store/' + getStoreId() + '/applications/setting/tiktok';
    case ApplicationType.GOOGLE_ANALYTICS:
      return '/store/' + getStoreId() + '/applications/setting/analytics';
    case ApplicationType.GOOGLE_TAG_MANAGER:
      return '/store/' + getStoreId() + '/applications/setting/google-tag-manager';
    case ApplicationType.GOOGLE_SHEET:
      return '/store/' + getStoreId() + '/applications/setting/google-sheet';
    case ApplicationType.NETWORK_ACCESS:
      return '/store/' + getStoreId() + '/applications/setting/network-access';
    case ApplicationType.FACEBOOK_CONVERSION_API:
      return '/store/' + getStoreId() + '/applications/setting/facebook-conversion-api';
    case ApplicationType.WHATSAPP:
      return '/store/' + getStoreId() + '/applications/setting/whatsapp';
    case ApplicationType.CATHEDIS:
      return '/store/' + getStoreId() + '/applications/setting/cathedis';
    case ApplicationType.SHIPSEN:
      return '/store/' + getStoreId() + '/applications/setting/shipsen';
    case ApplicationType.CLOAKYAN:
      return '/store/' + getStoreId() + '/applications/setting/cloakyan';
    default:
      return '/error/not-found';
  }
}

export const CHART_SCALES_X = {
  x: {
    border: {
      display: true
    },
    grid: {
      display: false,
      drawTicks: true,
    }
  },
  y: {
    border: {
      display: true
    },
    grid: {
      display: true
    },
  }
};
export const CHART_SCALES_Y = {
  y: {
    border: {
      display: true
    },
    grid: {
      display: false,
      drawTicks: true,
    }
  },
  x: {
    border: {
      display: true
    },
    grid: {
      display: true
    },
  }
};
