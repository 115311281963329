import { NgModule } from '@angular/core';
import { HasErrorsDirective } from './directive/has-error.directive';
import { FileUploadModule } from 'ng2-file-upload';
import { MaterialModule } from '@app/_shared/material.module';

import { NgxSpinnerModule } from 'ngx-spinner';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { YanLoadingButtonComponent } from './component/yan-loading-button/yan-loading-button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import {CommonModule, NgForOf} from '@angular/common';
import { BarRatingModule } from 'ngx-bar-rating';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldErrorComponent } from '@app/_shared/component/field-error/field-error.component';
import { ConfirmationDialogComponent } from '@app/_shared/component/confirmation-dialog/confirmation-dialog.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NoResultComponent } from './component/no-result/no-result.component';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { CustomersModalComponent } from '@app/_shared/component/customers-modal/customers-modal.component';
import { ProductsModalComponent } from '@app/_shared/component';
import { NgSelectModule } from '@ng-select/ng-select';
import {HasPermissionDirective} from "@app/_shared/directive/has-permission.directive";
import { NumbersOnlyDirective } from '@app/_shared/directive/numbers-only.directive';
import {DecimalsPercentOnlyDirective} from "@app/_shared/directive/decimals-percent-only.directive";
import {TranslateModule} from "@ngx-translate/core";
import {MessageComponent} from "@app/_shared/component/shopyan-header/message/message.component";
import {VersionComponent} from "@app/_shared/component/shopyan-header/version/version.component";
import {CarouselModule} from "ngx-owl-carousel-o";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    NgxSpinnerModule,
    SweetAlert2Module.forRoot(),
    FontAwesomeModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    MaterialModule,
    NgbPaginationModule,
    BarRatingModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    NgSelectModule,
    TranslateModule,
    CarouselModule,
    NgForOf
  ],
    declarations: [
        HasErrorsDirective,
        HasPermissionDirective,
        NumbersOnlyDirective,
        DecimalsPercentOnlyDirective,
        YanLoadingButtonComponent,
        FieldErrorComponent,
        ConfirmationDialogComponent,
        NoResultComponent,
        CustomersModalComponent,
        ProductsModalComponent,
        MessageComponent,
        VersionComponent
    ],
    exports: [
        /** Modules */
        CommonModule,
        FileUploadModule,
        NgxSpinnerModule,
        SweetAlert2Module,
        FontAwesomeModule,
        LoadingBarModule,
        LoadingBarRouterModule,
        MaterialModule,
        BarRatingModule,
        NgbPaginationModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        NgxMatTimepickerModule,
        NgSelectModule,

        /** Components */
        HasErrorsDirective,
        NumbersOnlyDirective,
        DecimalsPercentOnlyDirective,
        YanLoadingButtonComponent,
        FieldErrorComponent,
        ConfirmationDialogComponent,
        NoResultComponent,
        CustomersModalComponent,
        ProductsModalComponent,
        HasPermissionDirective,
    ],
})
export class SharedModule {
}
