import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {OwlOptions} from "ngx-owl-carousel-o";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();

  customOptions: OwlOptions = {
    dots: false,
    items: 1,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    nav: true,
    autoHeight: true,
    navText: ["<div class='arrow left-arrow'></div>", "<div class='arrow'></div>"],
    autoplay: true
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<VersionComponent>) {
  }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  urlMedia(media: string): string {
    return environment.mediaURL + 'admin/version/original/' + media + '.webp';
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
