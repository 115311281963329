import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EmployeeFilter, Pageable } from '@app/_shared';
import { environment } from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class EmployeeService {

    constructor(private http: HttpClient) {
    }

    /**
     * Filter employees
     * @param filter
     * @param pageable
     */
    public filterEmployees(filter: EmployeeFilter, pageable: Pageable): Observable<any> {

        let url = `${environment.storeApiUrl}/employees/filter`;

        let params = pageable ? pageable.getParams() : new HttpParams();
        if (filter?.text) {
            params = params.append('searchText', filter.text);
        }
        if (filter?.sent !== undefined) {
          params = params.append('sent', filter.sent);
        }
        if (filter?.declined !== undefined) {
          params = params.append('declined', filter.declined);
        }
        if (filter?.disabled !== undefined) {
          params = params.append('disabled', filter.disabled);
        }
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: params
        };

        return this.http.get(url, httpOptions);
    }

    /**
     * Get employee
     * @param employeeId
     */
    public getEmployee(employeeId?: string): Observable<any> {
      if(!employeeId) return of(null);

      let url = `${environment.storeApiUrl}/employees/${employeeId}`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

      return this.http.get(url, httpOptions);
    }

      /**
       * Delete employees
       * @param ids
       */
      public deleteEmployees(ids: string[]): Observable<any> {
          let url = `${environment.storeApiUrl}/employees/owner`;
          let params = new HttpParams();
          params = params.append('ids', ids.join(','));
          const httpOptions = {
              headers: new HttpHeaders({
                  'Content-Type': 'application/json'
              }),
              params: params
          };

          return this.http.delete(url, httpOptions);
      }

    /**
     * Accept invitation employee
     * @param confirmId
     */
    public getStoreFromInvitation(confirmId: string): Observable<any> {
      let url = `${environment.storeApiUrl}/employees/store-invitation/?confirmId=${confirmId}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

      return this.http.get(url, httpOptions);
    }

    /**
     * Resend invitation employee
     * @param username
     */
    public resendInvitation(username: string): Observable<any> {
        let url = `${environment.storeApiUrl}/employees/resend-invitation/?username=${username}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http.put(url, httpOptions);
    }


    /**
     * Accept invitation employee
     * @param confirmId
     */
    public acceptInvitation(confirmId: string): Observable<any> {
        let url = `${environment.storeApiUrl}/employees/accept-invitation/?confirmId=${confirmId}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http.put(url, httpOptions);
    }

    /**
     * decline invitation employee
     * @param confirmId
     */
    public declineInvitation(confirmId: string): Observable<any> {
        let url = `${environment.storeApiUrl}/employees/decline-invitation/?confirmId=${confirmId}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http.put(url, httpOptions);
    }


    /**
     * Enable/Disable employees
     * @param ids
     * @param availability
     */
    public availabilityEmployees(ids: string[], availability: boolean): Observable<any> {
        let url = `${environment.storeApiUrl}/employees/availability`;
        let params = new HttpParams();
        params = params.append('ids', ids.join(','));
        params = params.append('availability', availability);
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          }),
          params: params
        };

        return this.http.patch(url, null, httpOptions);
    }

    /**
     * count products
     */
    public countEmployees(): Observable<any> {
      let url = `${environment.storeApiUrl}/employees/count`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.get(url, httpOptions);
    }
}

