<div id="message-modal" class="yan-modal">
  <div class="close-modal" (click)="closeModal()"></div>
  <div
    class="yan-modal-title">{{ 'MESSAGE.MODAL.TITLE' | translate }}
  </div>
  <div class="yan-modal-content">
    <div class="message-details">
      <div class="key-item">
        <span class="k-value message-date">{{data.date | date: 'dd/MM/yyyy hh:mm'}}</span>
      </div>
      <div class="yan-flex">
        <div class="key-item">
          <span class="k-label">{{ 'MESSAGE.MODAL.STORE' | translate }}</span>
          <span class="k-value">{{data.store}}</span>
        </div>
        <div class="key-item">
          <span class="k-label">{{ 'MESSAGE.MODAL.NAME_LABEL' | translate }}</span>
          <span class="k-value">{{data.message.name}}</span>
        </div>
      </div>
      <div class="yan-flex">
        <div class="key-item">
          <span class="k-label">{{ 'MESSAGE.MODAL.MAIL_LABEL' | translate }}</span>
          <span class="k-value">{{data.message.mail}}</span>
        </div>
        <div class="key-item">
          <span class="k-label">{{ 'MESSAGE.MODAL.PHONE_LABEL' | translate }}</span>
          <span class="k-value">{{data.message.phone}}</span>
        </div>
      </div>
      <div class="key-item">
        <span class="k-label">{{ 'MESSAGE.MODAL.COMMENT_LABEL' | translate }}</span>
        <span class="k-value">{{data.message.comment}}</span>
      </div>
    </div>
  </div>
  <div class="yan-modal-btns">
    <div class="yan-btn white-btn"
         (click)="closeModal()"> {{ 'INTRO.CLOSE_FORM' | translate }}
    </div>
  </div>
</div>
