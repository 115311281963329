import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Pageable } from '@app/_shared';

@Injectable({providedIn: 'root'})
export class ReviewService {

    constructor(private http: HttpClient) {
    }


    /**
     * Filter reviews
     * @param productId
     * @param filter
     * @param pageable
     */
    public filterReviews(productId: string, pageable: Pageable): Observable<any> {
        let url = `${environment.productApiUrl}/reviews/${productId}`;
        let params = pageable ? pageable.getParams() : new HttpParams();

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: params
        };
        return this.http.get(url, httpOptions);

    }

    /**
     * Import aliexpress reviews
     * @param productId
     * @param language
     * @param onlyFiveStart
     */
    public importReviews(productId: string, language: string, onlyFiveStart: boolean): Observable<any> {
        const url = `${environment.productApiUrl}/reviews/import`;

        let params = new HttpParams();
        params = params.append('productId', productId);
        params = params.append('language', language);
        if (onlyFiveStart) {
            params = params.append('onlyFiveStart', onlyFiveStart);
        }
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: params
        };

        return this.http.get(url, httpOptions);
    }

    public handleError(error: any) {
        return throwError(error);
    }

    /**
     * Delete review
     * @param id
     */
    public deleteReview(id: string): Observable<any> {
        let url = `${environment.productApiUrl}/reviews/${id}`;
        let params = new HttpParams();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: params
        };

        return this.http.delete(url, httpOptions);
    }

    /**
     * Enable/Disable review
     * @param ids
     */
    public updateDisabled(id: string): Observable<any> {
        const url = `${environment.productApiUrl}/reviews/updateDisabled/${id}`;
        let params = new HttpParams();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: params
        };

        return this.http.patch(url, null, httpOptions);
    }


}

