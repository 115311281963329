import { PlanPaymentType } from '@app/_shared';
import {PlanType} from "@app/_shared/model/plan/plan-type.model";
import {StoreForm} from "@app/_shared/model/store/store.model";

export class PlanCheckout {
    storeForm?: StoreForm;
    selectedPlan: string;
    planType: PlanType;
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    zipCode: string;
    country: string;
    company: string;
    vatNumber: string;
    paymentType: PlanPaymentType;
    discountCode: string;
    creditCardData: CreditCardData;
}

export class CreditCardData {
    cardNumber: string;
    expiryDate: string;
    cvc: string;
}

export class PaymentInfos {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  zipCode: string;
  country: string;
  business: boolean;
  company: string;
  vatNumber: string;
}
