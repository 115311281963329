export class ProductVariation {
  id: string;
  code: string;
  optionValues: string[];
  price: number;
  basicPrice: number;
  quantity: number;
  sku: string;
  barCode: string;
  disabled: boolean;
  media?: string;
  imageLink?: string;
  totalOrders: number;
  asDefault: boolean;
}

