import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainLayoutComponent} from '@app/_shared/component/main-layout/main-layout.component';
import {ShopyanLayoutComponent} from '@app/_shared/component/shopyan-layout/shopyan-layout.component';
import {AppAuthGuard} from '@app/_shared/guard/app.authguard';
import {WelcomeAuthGuard} from "@app/_shared/guard/welcome.authguard";
import {GooglesheetOauth2Component} from "@app/googlesheet-oauth2/googlesheet-oauth2.component";


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'my-sites'
  },
  {
    path: 'account',
    component: MainLayoutComponent,
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'callback',
    component: GooglesheetOauth2Component
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'my-sites',
        loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule)
      },
      {
        path: 'redirects',
        loadChildren: () => import('./redirects/redirects.module').then(m => m.RedirectsModule)
      },
      {
        path: 'site-form',
        loadChildren: () => import('./site-form/site-form.module').then(m => m.SiteFormModule)
      },
      {
        path: 'checkout/:storeID',
        loadChildren: () => import('./store-checkout/store-checkout.module').then(m => m.StoreCheckoutModule)
      },
      {
        path: 'store/:storeID',
        component: ShopyanLayoutComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'home/overview'
          },
          {
            path: 'home',
            loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
          },
          {
            path: 'customers',
            loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule)
          },
          {
            path: 'guests',
            loadChildren: () => import('./guest/guest.module').then(m => m.GuestModule)
          },
          {
            path: 'newsletters',
            loadChildren: () => import('./newsletter/newsletter.module').then(m => m.NewsletterModule)
          },
          {
            path: 'orders',
            loadChildren: () => import('./order/order.module').then(m => m.OrderModule)
          },
          {
            path: 'products',
            loadChildren: () => import('./product/product.module').then(m => m.ProductModule)
          },
          {
            path: 'discounts',
            loadChildren: () => import('./discount/discount.module').then(m => m.DiscountModule)
          },
          {
            path: 'upsells',
            loadChildren: () => import('./upsell/upsell.module').then(m => m.UpsellModule)
          },
          {
            path: 'applications',
            loadChildren: () => import('./store-apps/apps.module').then(m => m.AppsModule)
          },
          {
            path: 'online',
            loadChildren: () => import('./online-site/online-site.module').then(m => m.OnlineSiteModule)
          },
          {
            path: 'settings',
            loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule)
          },
          {
            path: 'landing-pages',
            loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule)
          }
        ]
      },
    ]
  },
  {
    path: 'error',
    loadChildren: () => import('./error-page/error-page.module').then(m => m.ErrorPageModule)
  },
  {
    path: '**', redirectTo: 'error/not-found'
  }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, {
  //
  //     initialNavigation: 'enabled',
  //     // preload all modules; optionally we could
  //     // implement a custom preloading strategy for just some
  //     // of the modules
  //     preloadingStrategy: PreloadAllModules,
  //     onSameUrlNavigation: 'reload',
  //     scrollPositionRestoration: 'top'
  // })],
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule],
  providers: [AppAuthGuard, WelcomeAuthGuard]
})
export class AppRoutingModule {
}
