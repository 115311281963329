import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {Pageable} from "@app/_shared";

@Injectable({providedIn: 'root'})
export class BrandService {

    constructor(private http: HttpClient) {
    }


    /**
     * Get all brands
     */
    public getAll(pageable?: Pageable, searchText?: string): Observable<any> {

      const url = `${environment.productApiUrl}/brands`;
      let params = pageable ? pageable.getParams() : new HttpParams();
      if (searchText) {
        params = params.append('searchText', searchText);
      }

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        params: params
      };
      return this.http.get(url, httpOptions);
    }

    /**
     * Delete a Brand
     * @param brandId
     */
    public deleteBrand(brandId: string): Observable<any> {
      let url = `${environment.productApiUrl}/brands/${brandId}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.delete(url, httpOptions);
    }

}

