import { Product, Seo } from '@app/_shared';

export abstract class AbstractCollection {
    id: string;
    name: string;
    description: string;
    available: boolean;
    seo: Seo;
    media: string;
}

export class CollectionForm extends AbstractCollection{
    products: string[];
}

export class Collection extends AbstractCollection {
    products: Product[];
}



