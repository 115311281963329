<div id="products" class="yan-modal">
  <div class="close-modal" (click)="closeModal()"></div>
  <div class="yan-modal-title">{{ 'COMMON.PRODUCTS_MODAL.SELECT_PRODUCT' | translate }}</div>
  <div class="yan-modal-filter">
    <input type="text" (keyup)="search()" [(ngModel)]="filter" class="yan-input"
           placeholder="{{ 'COMMON.PRODUCTS_MODAL.SEARCH_PRODUCT' | translate }}"/>
  </div>
  <div class="yan-modal-content products-content">
    <ngx-spinner name="modal-spinner" type="ball-clip-rotate"></ngx-spinner>
    <ng-container *ngIf="!products || products.length == 0">
      <app-no-result></app-no-result>
    </ng-container>
    <ng-container *ngIf="products && products.length > 0">
      <div class="product-item" *ngFor="let product of products; let index = index">
        <div class="pi-container">
          <div class="product-checker">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="masterToggle(product)"
                          [disabled]="productPreSelection.isSelected(product.id) || (!productSelection.isSelected(product.id) && data.mono && !productSelection.isEmpty())"
                          [checked]="productSelection.isSelected(product.id)">
            </mat-checkbox>
          </div>
          <div class="product-picture"
               [class.no-media]="(!product.medias || product.medias.length == 0) && (!product.imageLinks || product.imageLinks.length == 0)">
            <fa-icon [icon]="faImage"
                     *ngIf="(!product.medias || product.medias.length ==0) && (!product.imageLinks || product.imageLinks.length ==0)"></fa-icon>
            <img [src]="urlSmallProductMedia(product.medias[0])" *ngIf="product.medias && product.medias.length > 0"/>
            <img [src]="product.imageLinks[0]"
                 *ngIf="(!product.medias || product.medias.length ==0) && (product.imageLinks && product.imageLinks.length > 0)"/>
          </div>
          <div class="product-inf">
            <div class="product-name">{{product.name}}</div>
            <div class="product-stock"
                 *ngIf="product.stock">{{product.stock}} {{ 'COMMON.PRODUCTS_MODAL.AVAILABLES' | translate }}</div>
          </div>
          <div class="product-price" *ngIf="product.pricing">
            <span>{{+product.pricing.price.toFixed(2) || 0}}</span>
            <span class="yan-sup">{{currency}}</span>
          </div>
        </div>
        <ng-container *ngIf="data.expanded && product.variations && product.variations.length > 0">
          <div class="variation-container" [class.first-item]="index == 0"
               *ngFor="let variation of product.variations; let index = index;">
            <div class="product-checker">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="variantSelection.toggle(variation.id)"
                            [disabled]="variantPreSelection.isSelected(variation.id)"
                            [checked]="variantSelection.isSelected(variation.id)">
              </mat-checkbox>
            </div>
            <div class="product-picture" [class.no-media]="!variation.media && !variation.imageLink">
              <fa-icon [icon]="faImage" *ngIf="!variation.media && !variation.imageLink"></fa-icon>
              <img [src]="urlSmallVariantMedia(variation.media)" *ngIf="variation.media"/>
              <img [src]="variation.imageLink" *ngIf="!variation.media"/>
            </div>
            <div class="product-inf">
              <div class="product-name">{{variation.optionValues.join(' | ')}}</div>
              <div class="product-stock"
                   *ngIf="variation.quantity">{{variation.quantity}} {{ 'COMMON.PRODUCTS_MODAL.AVAILABLES' | translate }}</div>
            </div>
            <div class="product-price">
              <span>{{variation.price || 0}}</span>
              <span class="yan-sup">{{currency}}</span>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="show-more yan-btn white-btn" *ngIf="displayShowMore" (click)="showMore()">
        {{ 'COMMON.PRODUCTS_MODAL.SHOW_MORE' | translate }}
      </div>
    </ng-container>
  </div>
  <div class="yan-modal-btns">
    <div class="yan-btn" (click)="submit()">{{ 'COMMON.PRODUCTS_MODAL.SAVE' | translate }}</div>
    <div class="yan-btn white-btn" (click)="closeModal()">{{ 'COMMON.PRODUCTS_MODAL.CANCEL' | translate }}</div>
  </div>
</div>
