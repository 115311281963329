import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CustomerForm, Pageable} from '@app/_shared';
import {environment} from '../../../environments/environment';
import {CustomerFilter} from "@app/_shared/model/customer/customer-filter.model";
import {CustomerStatusEnum} from "@app/_shared/enumeration/customer-status.enum";

@Injectable({providedIn: 'root'})
export class CustomerService {

  constructor(private http: HttpClient) {
  }

  count() {
    let url = `${environment.storeApiUrl}/customers/count`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ignoreError': 'true'
      }),
      responseType: 'text' as 'json'
    };

    return this.http.get(url, httpOptions);
  }

  /**
   * Filter customers to show in modals
   * @param filter
   * @param pageable
   */
  public filterShortListCustomers(filter: string, pageable: Pageable): Observable<any> {
    let url = `${environment.storeApiUrl}/customers/search`;


    let params = pageable ? pageable.getParams() : new HttpParams();
    if (filter) {
      params = params.append('searchText', filter);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);

  }

  /**
   * Filter customers to show in list
   * @param filter
   * @param pageable
   */
  public filterListCustomers(filter: CustomerFilter, pageable: Pageable): Observable<any> {
    let url = `${environment.storeApiUrl}/customers/filter`;

    let params = pageable ? pageable.getParams() : new HttpParams();
    if (filter?.text) {
      params = params.append('searchText', filter.text);
    }
    if (filter?.status) {
      params = params.append('status', filter?.status);
    }
    if (filter?.minSpent) {
      params = params.append('minSpent', filter?.minSpent);
    }
    if (filter?.maxSpent) {
      params = params.append('maxSpent', filter?.maxSpent);
    }
    if (filter?.minOrders) {
      params = params.append('minOrders', filter?.minOrders);
    }
    if (filter?.maxOrders) {
      params = params.append('maxOrders', filter?.maxOrders);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * Filter guests to show in list
   * @param filter
   * @param pageable
   */
  public filterListGuests(filter: string, pageable: Pageable): Observable<any> {
    let url = `${environment.orderApiUrl}/guests/filter`;

    let params = pageable ? pageable.getParams() : new HttpParams();
    if (filter) {
      params = params.append('text', filter);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);

  }

  get(id: String): Observable<any> {
    let url = `${environment.storeApiUrl}/customers/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.get(url, httpOptions);
  }

  create(customer: CustomerForm): Observable<any> {
    let url = `${environment.storeApiUrl}/customers`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'text' as 'json'
    };

    return this.http.post(url, customer, httpOptions);
  }

  update(customer: CustomerForm): Observable<any> {
    let url = `${environment.storeApiUrl}/customers`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'text' as 'json'
    };

    return this.http.put(url, customer, httpOptions);
  }

  delete(ids: string[]): Observable<any> {
    let url = `${environment.storeApiUrl}/customers`;
    let params = new HttpParams();
    params = params.append('ids', ids.join(','));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.delete(url, httpOptions);
  }

  changeCustomerStatus(ids: string[], status: CustomerStatusEnum) {
    let url = `${environment.storeApiUrl}/customers/status`;
    let params = new HttpParams();
    params = params.append('ids', ids.join(','));
    params = params.append('status', status);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.patch(url, null, httpOptions);
  }

  /**
   * Get last customers
   */
  public getLastCustomers(): Observable<any> {
    let url = `${environment.storeApiUrl}/customers/last`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    return this.http.get(url, httpOptions);
  }


  /**
   * Filter newsletters to show in list
   * @param filter
   * @param pageable
   */
  public filterNewsletters(filter: string, pageable: Pageable): Observable<any> {
    let url = `${environment.storeApiUrl}/newsletters/filter`;

    let params = pageable ? pageable.getParams() : new HttpParams();
    if (filter) {
      params = params.append('searchText', filter);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);

  }

}

