export enum UpsellPage {
  PRODUCT = 'PRODUCT',
  CART = 'CART',
  CHECKOUT = 'CHECKOUT'
}

export const upsellPages: any[] = Object.keys(UpsellPage).map(item => {
  return {
    key: item,
    value: Object.values(UpsellPage)[Object.keys(UpsellPage).indexOf(item as unknown as UpsellPage)]
  }
});

