import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {DiscountFilter, Pageable} from '@app/_shared';

@Injectable({providedIn: 'root'})
export class DiscountService {

    constructor(private http: HttpClient) {
    }


    /**
     * Filter discounts
     * @param storeId
     * @param filter
     * @param pageable
     */
    public filterDiscounts(storeId: string, filter: DiscountFilter, pageable: Pageable): Observable<any> {
        let url = `${environment.discountApiUrl}/discounts/filter`;
        let params = pageable ? pageable.getParams() : new HttpParams();
        if (filter?.text) {
            params = params.append('text', filter.text);
        }
        if (filter?.type) {
            params = params.append('type', filter.type);
        }
        if (filter?.available) {
          params = params.append('available', true);
        }

        if (filter?.available === false) {
          params = params.append('available', false);
        }
        if (filter?.period) {
            params = params.append('period', '' + filter.period);
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: params
        };
        return this.http.get(url, httpOptions);

    }

    /**
     * Delete discounts
     * @param storeId
     * @param ids
     */
    public deleteDiscounts(ids: string[]): Observable<any> {
        let url = `${environment.discountApiUrl}/discounts`;
        let params = new HttpParams();
        params = params.append('ids', ids.join(','));
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: params
        };

        return this.http.delete(url, httpOptions);
    }


  /**
   * Enable/Disable discounts
   * @param ids
   * @param availability
   */
  public updateAvailability(ids: string[], availability: boolean): Observable<any> {
    let url = `${environment.discountApiUrl}/discounts/availability`;
    let params = new HttpParams();
    params = params.append('ids', ids.join(','));
    params = params.append('availability', availability);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.patch(url, null, httpOptions);
  }


  public generateCode(): Observable<string>  {
    let url = `${environment.discountApiUrl}/discounts/generate`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain'
      }),
      responseType: 'text' as 'json'
    };
    return this.http.get<string>(url, httpOptions);
  }
}

