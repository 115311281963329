<div id="customers" class="yan-modal">
    <div class="close-modal" (click)="closeModal()"></div>
    <div class="yan-modal-title">{{ 'COMMON.CUSTOMERS_MODAL.SELECT_CUSTOMER' | translate }}</div>
    <div class="yan-modal-filter">
        <input type="text" (keyup)="search()" [(ngModel)]="filter" class="yan-input" placeholder="{{ 'COMMON.CUSTOMERS_MODAL.SEARCH_CUSTOMER' | translate }}"/>
    </div>
    <div class="yan-modal-content customers-content">
        <ngx-spinner name="modal-spinner" type="ball-clip-rotate"></ngx-spinner>
        <ng-container *ngIf="!customers || customers.length == 0">
            <div class="no-result">{{ 'COMMON.CUSTOMERS_MODAL.CUSTOMER_NOT_FOUND' | translate }}</div>
        </ng-container>
        <ng-container *ngIf="customers && customers.length > 0">
            <div class="customer-item" *ngFor="let customer of customers">
                <div class="customer-avatar yan-avatar">
                  <div class="default-avatar">{{(customer?.firstName?.toUpperCase()?.charAt(0) || '')
                  + customer?.lastName?.toUpperCase()?.charAt(0)}}</div>
                </div>
                <div class="customer-name">{{customer.firstName + ' ' + customer.lastName}}</div>
                <div class="customer-mail">{{customer.email}}</div>
                <div class="customer-check">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="selection.toggle(customer.id)"
                                  [disabled]="preSelection.isSelected(customer.id)"
                                  [checked]="selection.isSelected(customer.id)">
                    </mat-checkbox>
                </div>
            </div>
            <div class="show-more yan-btn white-btn" *ngIf="displayShowMore" (click)="showMore()">
              {{ 'COMMON.CUSTOMERS_MODAL.SHOW_MORE' | translate }}
            </div>
        </ng-container>
    </div>
    <div class="yan-modal-btns">
        <div class="yan-btn" (click)="submit()">{{ 'COMMON.CUSTOMERS_MODAL.SAVE' | translate }}</div>
        <div class="yan-btn white-btn" (click)="closeModal()">{{ 'COMMON.CUSTOMERS_MODAL.CANCEL' | translate }}</div>
    </div>
</div>
