import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {RxStomp} from "@stomp/rx-stomp";

@Injectable({providedIn: 'root'})
export class SocketService {

  rxStomp: RxStomp;

  constructor(private http: HttpClient) {
  }


  connect(token:string) {
    if(!this.rxStomp || !this.rxStomp.connected()) {
      this.rxStomp = new RxStomp();
      this.rxStomp.configure({
        brokerURL: environment.socketUrl,
        connectHeaders: {
          passcode: token
        },
      });
      this.rxStomp.activate();
    }
  }

  async disconnect() {
    if (!this.rxStomp || this.rxStomp.connected()) {
      await this.rxStomp.deactivate();
    }
  }

}

