<div class="yan-modal">
    <div class="yan-modal-title"> {{ 'COMMON.CONFIRMATION_DIALOG.TITLE' | translate }}</div>
    <div class="yan-modal-content">
        <div class="confirmation-message">{{message}}</div>
    </div>
    <div class="yan-modal-btns">
        <button [mat-dialog-close]="true" class="yan-btn submit">{{ 'COMMON.CONFIRMATION_DIALOG.CONFIRM' | translate }}</button>
        <button [mat-dialog-close]="false" class="yan-btn reset">{{ 'COMMON.CONFIRMATION_DIALOG.CANCEL' | translate }}</button>
    </div>
</div>

