import {HttpClient} from '@angular/common/http';
import {TranslocoLoader} from '@ngneat/transloco';
import {Injectable} from '@angular/core';
import cacheBusting from '../../i18n-cache-busting.json';

@Injectable({ providedIn: 'root' })
export class HttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    // @ts-ignore
    const path = `/assets/i18n/${lang}.json?v=${cacheBusting[lang]}`;
    return this.http.get(path);
  }
}
