import {DiscountType} from '@app/_shared';

export abstract class AbstractDiscount {
  id?: string;
  type: DiscountType;
  discountCode: string;
  title: string;
  startDate: Date;
  endDate: Date;
  fixedAmount: number;
  percent: number;
  minimumRequirementAmount: number;
  minimumRequirementQuantity: number;
  totalUsesLimit: number;
  appliesOncePerCustomer: boolean;
  countries: string;
  expeditionDiscountAmount: number;

}

export class Discount extends AbstractDiscount {
  products: ProductVariationObject[];

  collections: ObjectValue[];
  customers: SharedCustomer[];

  available: boolean;

  /**
   * Initialized by the back
   */
  currentTotalUses: number;

}

export class DiscountForm extends AbstractDiscount {
  products: ProductVariationForm[];
  collections: string[];
  customers: string[];

}

export class ProductVariationForm {
  id: string;
  variations: string[];
}

export class ProductVariationObject {
  id: string;
  name: string;
  medias: string[];
  variations?: VariationOptionDiscount[];
}

export class VariationOptionDiscount {
  id: string;
  optionValues: string[];
  medias: string[];

}

export class SharedCustomer {
  id: string;
  firstName: string;
  lastName: string;
  email: string;

}

export class ObjectValue {
  id: string;
  name: string;
  media: string;
}

export class DiscountDetail {
  id: string;
  code: string;
  label: string;
  value?: number;
  percent?: number;
}
