import {FeatureType} from '@app/_shared';
import {PlanFeatureType} from "@app/_shared/enumeration/plan-feature-type.enum";

export const storeMenuItems = [
  {
    'code': 'MENU_HOME',
    'className': 'home',
    'url': '/store/:storeID/home/overview',
    'subMenus': [
      {
        'code': 'SUB_OVERVIEW',
        'url': '/store/:storeID/home/overview',
        'feature': FeatureType.DASHBOARD_OVERVIEW.toString()
      },
      {
        'code': 'SUB_ANALYTICS',
        'url': '/store/:storeID/home/analytics',
        'feature': FeatureType.ANALYTICS.toString()
      },
      {
        'code': 'SUB_LIVE',
        'url': '/store/:storeID/home/live',
        'feature': FeatureType.LIVE_VIEW.toString(),
        'planFeature': PlanFeatureType.LIVE_VIEW
      },
    ]
  },
  {
    'code': 'MENU_PRODUCT',
    'className': 'product',
    'url': '/store/:storeID/products/list',
    'subMenus': [
      {
        'code': 'SUB_ALL_PRODUCTS',
        'url': '/store/:storeID/products/list',
        'feature': FeatureType.PRODUCTS.toString()
      },
      {
        'code': 'SUB_COLLECTIONS',
        'url': '/store/:storeID/products/collections',
        'feature': FeatureType.COLLECTIONS.toString()
      },
      {
        'code': 'SUB_INVENTORY',
        'url': '/store/:storeID/products/inventory',
        'feature': FeatureType.INVENTORY.toString()
      },
      {
        'code': 'SUB_SUPPLIERS',
        'url': '/store/:storeID/products/suppliers',
        'feature': FeatureType.SUPPLIERS.toString()
      },
      {
        'code': 'SUB_BRANDS',
        'url': '/store/:storeID/products/brands',
        'feature': FeatureType.BRANDS.toString()
      }
    ]
  },
  {
    'code': 'MENU_LANDING_PAGE',
    'className': 'landing-page',
    'planFeature': PlanFeatureType.LANDING_PAGE,
    'feature': FeatureType.LANDING_PAGE.toString(),
    'url': '/store/:storeID/landing-pages'
  },
  {
    'code': 'MENU_ORDER',
    'className': 'order',
    'url': '/store/:storeID/orders/list',
    'hasCounter': true,
    'subMenus': [
      {
        'code': 'SUB_ALL_ORDERS',
        'url': '/store/:storeID/orders/list',
        'feature': FeatureType.ORDERS.toString()
      },
      {
        'code': 'SUB_INVOICES',
        'url': '/store/:storeID/orders/invoices',
        'feature': FeatureType.INVOICES.toString()
      }
    ]
  },
  {
    'code': 'MENU_UPSELL',
    'className': 'upsell',
    'feature': FeatureType.UPSELL.toString(),
    'planFeature': PlanFeatureType.UPSELL,
    'hasCounter': true,
    'url': '/store/:storeID/upsells/list'
  },
  {
    'code': 'MENU_CUSTOMER',
    'className': 'customer',
    'feature': FeatureType.CUSTOMERS.toString(),
    'hasCounter': true,
    'url': '/store/:storeID/customers',
    'subMenus': [
      {
        'code': 'SUB_ALL_CUSTOMERS',
        'url': '/store/:storeID/customers',
        'feature': FeatureType.CUSTOMERS.toString()
      },
      {
        'code': 'SUB_GUESTS',
        'url': '/store/:storeID/guests',
        'feature': FeatureType.CUSTOMERS.toString()
      },
      {
        'code': 'SUB_NEWSLETTERS',
        'url': '/store/:storeID/newsletters',
        'feature': FeatureType.CUSTOMERS.toString()
      }
    ]
  },
  {
    'code': 'MENU_DISCOUNT',
    'className': 'discount',
    'feature': FeatureType.DISCOUNTS.toString(),
    'url': '/store/:storeID/discounts'
  },
  {
    'code': 'MENU_APPS',
    'className': 'application',
    'feature': FeatureType.APPLICATIONS.toString(),
    'planFeature': PlanFeatureType.APPS,
    'url': '/store/:storeID/applications/marketplace',
    'subMenus': [
      {
        'code': 'SUB_MARKETPLACE',
        'url': '/store/:storeID/applications/marketplace',
        'feature': FeatureType.APPLICATIONS.toString(),
        'planFeature': PlanFeatureType.APPS
      },
      {
        'code': 'MY_APPS',
        'url': '/store/:storeID/applications/list',
        'feature': FeatureType.APPLICATIONS.toString(),
        'planFeature': PlanFeatureType.APPS
      },
    ]
  },
  {
    'code': 'MENU_SITE',
    'className': 'online-site',
    'feature': FeatureType.CUSTOMIZATIONS.toString(),
    'planFeature': PlanFeatureType.BUILDER,
    'url': '/store/:storeID/online/customization',
    'subMenus': [
      {
        'code': 'SUB_CUSTOMIZE',
        'feature': FeatureType.CUSTOMIZATIONS.toString(),
        'planFeature': PlanFeatureType.BUILDER,
        'url': '/store/:storeID/online/customization',
      },
      {
        'code': 'SUB_THEMES',
        'feature': FeatureType.CUSTOMIZATIONS.toString(),
        'planFeature': PlanFeatureType.BUILDER,
        'url': '/store/:storeID/online/themes',
      },
      {
        'code': 'SUB_NAVIGATION',
        'feature': FeatureType.CUSTOMIZATIONS.toString(),
        'planFeature': PlanFeatureType.BUILDER,
        'url': '/store/:storeID/online/navigation',
      }
    ]
  }
];
