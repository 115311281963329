<div id="version-modal" class="yan-modal">
  <div class="close-modal" (click)="closeModal()"></div>
  <div
    class="yan-modal-title">{{ 'COMMON.SHOPYAN_VERSION_TITLE' | translate }} {{data.createDate | date: 'dd/MM/yyyy'}}
  </div>
  <div class="yan-modal-content version-carousel">
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide *ngFor="let item of data.gallery; let i = index">
        <img [src]="urlMedia(item)" height="500"/>
      </ng-template>
    </owl-carousel-o>
  </div>
</div>
