import {Product} from "@app/_shared";

export abstract class AbstractSupplier {

  id: string;
  name: string;
  description: string;
  media: string;

}

export class SupplierForm extends AbstractSupplier {
  products: string[];
}

export class Supplier extends AbstractSupplier {
  products: Product[];
}


