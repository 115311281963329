import {DiscountDetail, Product, ProductVariation, Upsell} from '@app/_shared';

export class OrderItemAbstract {
  id: string;
  quantity: number;
  total: number;
  discounts: DiscountDetail[];
}

export class OrderItem extends OrderItemAbstract {
  product: Product;
  variant: ProductVariation;
  upsell: Upsell;
}

export class OrderItemForm extends OrderItemAbstract {
  product: string;
  variant: string;
  upsell: string;
}

