import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Customer, CustomerService, Pageable, YanLoaderService} from '@app/_shared';
import {SelectionModel} from '@angular/cdk/collections';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-customers-modal',
    templateUrl: './customers-modal.component.html',
    styleUrls: ['./customers-modal.component.scss']
})
export class CustomersModalComponent implements OnInit, OnDestroy {

    subscription: Subscription = new Subscription();

    pageable = new Pageable();
    filter: string;
    displayShowMore = false;

    customers: Customer[] = [];
    selection = new SelectionModel<any>(true, []);
    preSelection = new SelectionModel<any>(true, []);

    constructor(public dialogRef: MatDialogRef<CustomersModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private customerService: CustomerService,
                private yanLoaderService: YanLoaderService) {
    }


    ngOnInit(): void {
        this.pageable.size = 5;
        this.data.customers?.forEach((customer: Customer) => {
            this.selection.select(customer.id);
            this.preSelection.select(customer.id);
        });
        this.filterCustomers();
    }

    /**
     * Input search changed
     */
    search(): void {
        this.pageable.page = 1;
        this.filterCustomers();
    }

    /**
     * Filter products
     */
    filterCustomers(showMore?: boolean): void {
        this.yanLoaderService.startLoader('modal-spinner');
        const sub = this.customerService.filterShortListCustomers(this.filter, this.pageable).subscribe(response => {
            if (!showMore) {
                this.customers = [...response.data];
            } else {
                this.customers = [...this.customers, ...response.data];
            }

            if (response?.data?.length === this.pageable.size) {
                this.displayShowMore = true;
            } else {
                this.displayShowMore = false;
            }
            this.yanLoaderService.stopLoader('modal-spinner');
        });
        this.subscription.add(sub);
    }


    /**
     * Show more products
     */
    showMore(): void {
        this.pageable.page++;
        this.filterCustomers(true);
    }

    /**
     * Submit selections
     */
    submit() {
      const selectedCustomers: Customer[] = [];
      this.selection.selected.forEach(item => {
        const customer = this.customers.find(c => c.id === item);
        if (customer) {
          selectedCustomers.push(customer);
        }
      });
      this.dialogRef.close(selectedCustomers);
    }


    /**
     *Close modal
     */
    closeModal(): void {
        this.dialogRef.close();
    }

    /**
     * Unsubscribe
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
