export class Theme {
  id: string;
  name: string;
  media: string;
  category: ThemeCategory;
  slug: string;
  createdDate: Date;
  defaultTheme: boolean;
  disabled: boolean;
  previewURL: string;
}

export enum ThemeCategory {
  CLOTHING_FASHION = 'CLOTHING_FASHION',
  ART_HOME_DECOR = 'ART_HOME_DECOR',
  ELECTRONIC_GADGET = 'ELECTRONIC_GADGET',
  JEWELRY_ACCESSORIES = 'JEWELRY_ACCESSORIES',
  FOOD_DRINK = 'FOOD_DRINK',
  BEAUTY_WELLNESS = 'BEAUTY_WELLNESS',
  PETS = 'PETS',
  FITNESS = 'FITNESS',
  AUTO_MOTO = 'AUTO_MOTO',
  GAMES = 'GAMES',
  HOUSEHOLD_PRODUCTS = 'HOUSEHOLD_PRODUCTS',
  TRAVEL_TRIP = 'TRAVEL_TRIP',
  BOOKS = 'BOOKS',
  PLANTS = 'PLANTS',
  OTHER = 'OTHER'
}
