import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class YanToastrService {

    constructor(private toastr: ToastrService) {

    }

    success(message: string) {
        if (!message) {
            message = 'Success';
        }
        this.toastr.success(message);
    }

    error(message: string) {
        if (!message) {
            message = 'Error';
        }
        this.toastr.error(message);
    }

    warn(message: string) {
        if (!message) {
            message = 'Warning';
        }
        this.toastr.warning(message);
    }

}
