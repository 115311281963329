import {Component, ElementRef, ViewChild} from '@angular/core';
import {FileUploader} from 'ng2-file-upload';
import {getStoreId, YanLoaderService, YanToastrService} from '@app/_shared';
import {KeycloakService} from 'keycloak-angular';

@Component({
  template: ''
})
export abstract class FileUploaderComponent {

  // File uploader
  uploader: FileUploader;

  protected maxSize: number;
  protected mimeTypes: string[] = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'image/gif', 'image/webp'];
  public maxFiles: number;
  protected uploadURL: string;


  uploaderHasDropZoneOver: boolean = false;
  @ViewChild('fileUploaderInput') fileUploaderInput: ElementRef;

  protected constructor(protected loaderService: YanLoaderService,
                        protected toastrService: YanToastrService,
                        protected keycloakService: KeycloakService) {
  }


  initUploader() {

    // Init the uploader
    this.uploader = new FileUploader({
      autoUpload: false,
      url: this.uploadURL,
      allowedMimeType: this.mimeTypes,
      maxFileSize: this.maxSize,
      removeAfterUpload: true,
      authTokenHeader: 'Authorization',
      headers: [{
        name: 'storeID',
        value: getStoreId()
      }]
    });


    // Catch failed file
    this.uploader.onWhenAddingFileFailed = (item: any, filter: any, options: any) => {
      let errorMessage = 'Failed';
      if (filter) {
        if (filter.name === 'mimeType') {
          errorMessage = 'Invalid type';

        } else if (filter.name === 'fileSize') {
          errorMessage = 'Max size';
        }
      }
      this.toastrService.error(errorMessage);
    };

    // After adding a file
    this.uploader.onAfterAddingFile = async (item) => {
      if (this.maxFiles && this.totalUploadedFiles >= this.maxFiles) {
        this.toastrService.error('Max files allowed');
      } else {
        if (this.keycloakService.isTokenExpired()) {
          await this.keycloakService.updateToken(60).then(async response => {
            if (!response) {
              await this.keycloakService.login({
                redirectUri: window.location.toString()
              });
            }
          });
        }
        this.uploader.authToken = 'bearer ' + this.keycloakService.getKeycloakInstance().token;
        item.withCredentials = false;
        item.upload();
        this.loaderService.startProgressBar();
      }
    }

    // On error item
    this.uploader.onErrorItem = ((item, response, status, headers): any => {
      let responses;
      if (response) {
        responses = JSON.parse(response);
      }
      let message = 'Error while uploading the file, try again !';
      if (status === 400 && responses && responses.length > 0) {
        message = responses[0];
      }
      this.toastrService.error(message);
      this.loaderService.stopProgressBar();
    });

    // On success item
    this.uploader.onSuccessItem = ((item, response, status, headers): any => {
      if (this.maxFiles && this.totalUploadedFiles >= this.maxFiles) {
        this.toastrService.error('Max files allowed');
      } else {
        this.pushNewFile(response);
      }
      this.loaderService.stopProgressBar();
    });

  }

  abstract get totalUploadedFiles(): number;

  abstract pushNewFile(fileCode: String): void;

  public fileOverUploader(e: any): void {
    this.uploaderHasDropZoneOver = e;
  }

  public openFileUploader() {
    this.fileUploaderInput.nativeElement.click();
  }

}
