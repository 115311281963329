import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class RefDataService {

    constructor(private http: HttpClient) { }

    /**
     * Get all currencies
     * @param url
     */
    public getCurrencies(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http
            .get(`${environment.storeApiUrl}/refData/currencies`, httpOptions)
            .pipe(catchError(this.handleError));
    }

    /**
     * Get all store sectors
     */
    public getSectors(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http
            .get(`${environment.storeApiUrl}/refData/sectors`, httpOptions)
            .pipe(catchError(this.handleError));
    }

    /**
     * Get all countries
     */
    public getCountries(): Observable<any> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http
        .get(`${environment.storeApiUrl}/refData/countries`, httpOptions)
        .pipe(catchError(this.handleError));
    }

  /**
   * Get country
   */
  public getCountry(code: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .get(`${environment.storeApiUrl}/refData/countries/${code}`, httpOptions)
      .pipe(catchError(this.handleError));
  }

    /**
     * Get all cancel reasons
     * @param url
     */
    public getCancelReasons(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http
            .get(`${environment.orderApiUrl}/refData/cancel-reasons`, httpOptions)
            .pipe(catchError(this.handleError));
    }

    /**
     * Handler request error
     * @param error
     */
    public handleError(error: any) {
        return throwError(error);
    }
}
