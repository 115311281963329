export enum FeatureType {
  DASHBOARD_OVERVIEW = 'DASHBOARD_OVERVIEW',
  ANALYTICS = 'ANALYTICS',
  LIVE_VIEW = 'LIVE_VIEW',
  PRODUCTS = 'PRODUCTS',
  COLLECTIONS = 'COLLECTIONS',
  INVENTORY = 'INVENTORY',
  SUPPLIERS = 'SUPPLIERS',
  BRANDS = 'BRANDS',
  LANDING_PAGE = 'LANDING_PAGE',
  ORDERS = 'ORDERS',
  INVOICES = 'INVOICES',
  CUSTOMERS = 'CUSTOMERS',
  DISCOUNTS = 'DISCOUNTS',
  UPSELL = 'UPSELL',
  APPLICATIONS = 'APPLICATIONS',
  CUSTOMIZATIONS = 'CUSTOMIZATIONS',
  GENERAL_PARAMETERS = 'GENERAL_PARAMETERS',
  CHECKOUT_PARAMETERS = 'CHECKOUT_PARAMETERS',
  DOMAINES_PARAMETERS = 'DOMAINES_PARAMETERS',
  NOTIFICATIONS_PARAMETERS = 'NOTIFICATIONS_PARAMETERS',
  LANGAGES_CURRENCY_PARAMETERS = 'LANGAGES_CURRENCY_PARAMETERS',
  SHIPPING_ZONES_PARAMETERS = 'SHIPPING_ZONES_PARAMETERS',
  TAXES_PARAMETERS = 'TAXES_PARAMETERS',
  SEO_PARAMETERS = 'SEO_PARAMETERS'
}
