export enum PlanFeatureType {
  EXPORT_STORE = 'EXPORT_STORE',
  IMPORT_PRODUCT = 'IMPORT_PRODUCT',
  LIVE_VIEW = 'LIVE_VIEW',
  CHATGPT = 'CHATGPT',
  BUILDER = 'BUILDER',
  DUPLICATE_STORE = 'DUPLICATE_STORE',
  DOMAIN = 'DOMAIN',
  APPS = 'APPS',
  PRODUCTS = 'PRODUCTS',
  USERS = 'USERS',
  UPSELL = 'UPSELL',
  PAYMENTS = 'PAYMENTS',
  ORDERS = 'ORDERS',
  SALES = 'SALES',
  LANDING_PAGE = 'LANDING_PAGE'
}
