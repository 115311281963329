import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {StoreCacheService} from "@app/_shared/service/store-cache.service";
import {YanLoaderService} from "@app/_shared";
import {Router} from "@angular/router";
import {SubscriptionStatus} from "@app/_shared/enumeration/subscription-status.enum";

@Component({
  selector: 'app-store-layout',
  templateUrl: './shopyan-layout.component.html',
  styleUrls: ['./shopyan-layout.component.scss']
})
export class ShopyanLayoutComponent implements OnInit {

  /**
   * Childs
   */
  @ViewChild('shopyanNav') shopyanNav: ElementRef;

  /**
   * To manage left menu  hide & show
   */
  leftNavigationMenuCollapsed = false;

  // Icons
  faArrowLeft = faArrowLeft;

  ready: boolean;

  hoverAllowed = true;

  constructor(private storeCacheService: StoreCacheService,
              private router: Router,
              private yanLoaderService: YanLoaderService) {
  }

  ngOnInit(): void {
    if (!this.storeCacheService.fromStore && !this.storeCacheService.initialized) {
      this.yanLoaderService.startLoader('layout-spinner');
      this.storeCacheService.getStoreCacheData('').subscribe({
        next: data => {
          if (![SubscriptionStatus.PAID, SubscriptionStatus.CANCELING, SubscriptionStatus.PAYMENT_FAILED].includes(data.subscriptionStatus)) {
            this.router.navigateByUrl(`/checkout/${data.store.id}`).then();
            this.yanLoaderService.stopLoader('layout-spinner');
            return;
          }
          this.storeCacheService.initStoreCacheData(data);
          this.ready = true;
          this.yanLoaderService.stopLoader('layout-spinner');
        }, error: error => {
          this.router.navigateByUrl('/my-sites').then();
          this.yanLoaderService.stopLoader('layout-spinner');
        }
      });
    } else {
      this.ready = true;
    }
  }

  /**
   * Toggle left navigation menu
   */
  toggleLeftNavigationMenu(): void {
    this.leftNavigationMenuCollapsed = !this.leftNavigationMenuCollapsed;
    this.freezeHoverAction();
    if (this.leftNavigationMenuCollapsed) {
      this.shopyanNav.nativeElement.classList.remove('nav-hover');
    } else {
      this.shopyanNav.nativeElement.classList.add('nav-hover');
    }
  }

  leftMenuMouseEnter(): void {
    if (this.leftNavigationMenuCollapsed && this.hoverAllowed) {
      this.shopyanNav.nativeElement.classList.add('nav-hover');
    }
  }

  leftMenuMouseLeave(): void {
    if (this.leftNavigationMenuCollapsed && this.hoverAllowed) {
      this.shopyanNav.nativeElement.classList.remove('nav-hover');
    }
  }

  private freezeHoverAction(): void {
    this.hoverAllowed = false;
    setTimeout(() => {
      this.hoverAllowed = true;
    }, 500);
  }


  get normalPage(): boolean {
    return this.router.url.indexOf("live") < 0;
  }

}
