<div id="shopyan-layout">
  <ngx-spinner name="layout-spinner" type="ball-clip-rotate"></ngx-spinner>

  <ng-container *ngIf="ready">
    <!-- Navigation menu -->
    <div class="shopyan-navigation"
         (mouseover)="leftMenuMouseEnter()"
         (mouseout)="leftMenuMouseLeave()">

      <div class="shopyan-navigation-content" #shopyanNav
           [class.collapsed]="leftNavigationMenuCollapsed">
        <div class="nav-toggler" (click)="toggleLeftNavigationMenu()">
          <fa-icon [icon]="faArrowLeft"></fa-icon>
        </div>

        <div class="sn-content">
          <app-left-menu></app-left-menu>
        </div>
      </div>

    </div>

    <!-- App main body -->
    <div class="shopyan-body" [class.collapsed-left-menu]="leftNavigationMenuCollapsed">
      <div class="shopyan-body-content" [class.with-padding]="normalPage">
        <div id="page-anchor" class="scroll-anchor"></div>

        <!-- Main notifications -->
        <div class="main-notifs" *ngIf="false"></div>

        <!-- Page content -->
        <router-outlet></router-outlet>
      </div>
    </div>
  </ng-container>
</div>
