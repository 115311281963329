export enum OrderStatus {
  NEW = 'NEW',
  CONFIRMATION_IN_PROGRESS = 'CONFIRMATION_IN_PROGRESS',
  CONFIRMED = 'CONFIRMED',
  SHIPPING_IN_PROGRESS = 'SHIPPING_IN_PROGRESS',
  SHIPPED = 'SHIPPED',
  TREATED = 'TREATED',
  CANCELED = 'CANCELED',
  PARTIALLY_TREATED = 'PARTIALLY_TREATED',
  UNTREATED = 'UNTREATED'
}
