import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Pageable } from '@app/_shared';

@Injectable({providedIn: 'root'})
export class ApplicationService {

    constructor(private http: HttpClient) {
    }


    /**
     * Filter applications
     * @param searchText
     */
    public filterApplications(searchText?: string, pageable?: Pageable): Observable<any> {

        const url = `${environment.storeApiUrl}/applications/filter`;
        let params = pageable ? pageable.getParams() : new HttpParams();
        if (searchText) {
            params = params.append('searchText', searchText);
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: params
        };
        return this.http.get(url, httpOptions);
    }


    /**
     * Filter my applications
     * @param searchText
     */
    public filterMyApplications(searchText?: string, pageable?: Pageable): Observable<any> {

        const url = `${environment.storeApiUrl}/applications/my-applications`;
        let params = pageable ? pageable.getParams() : new HttpParams();
        if (searchText) {
            params = params.append('searchText', searchText);
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: params
        };
        return this.http.get(url, httpOptions);
    }


    /**
     * Install/Uninstall applications
     * @param searchText
     */
    public installApplication(applicationId: string, uninstall: boolean): Observable<any> {

        let url = `${environment.storeApiUrl}/applications/install/${applicationId}`;
        if (uninstall) {
            url = `${environment.storeApiUrl}/applications/uninstall/${applicationId}`;
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
        };
        return this.http.patch(url, null, httpOptions);
    }

  /**
   * get NETWORK_ACCESS applications data.
   */
  public getNetworkAccess(): Observable<any> {

    const url = `${environment.storeApiUrl}/applications/NETWORK_ACCESS`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    return this.http.get(url, httpOptions);
  }


}

