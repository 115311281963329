import {getStoreId, MediaSize, MediaType} from "@app/_shared";
import {environment} from "../../../environments/environment";

export function urlMedia(mediaType: MediaType, mediaSize: MediaSize, media: string): string {
  return environment.mediaURL + getStoreId() + '/' + mediaType.toLowerCase() + '/' + mediaSize + '/' + media + '.webp';
}

export function urlAppMedia(mediaSize: MediaSize, media: string): string {
  return environment.mediaURL + 'admin/' + MediaType.APPLICATION.toLowerCase() + '/' + mediaSize + '/' + media + '.webp';
}
