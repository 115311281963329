<div id="shopyan-header">

  <div class="header-lp">
    <a [routerLink]="['/my-sites']" class="shopyan-logo"></a>

    <ng-container *ngIf="showStoresMenu && storeCacheService.store">
      <div class="sites" [matMenuTriggerFor]="storesMenu" (click)="loadStores()">
        <span>{{ storeCacheService.store.name }}</span>
        <fa-icon [icon]="faAngleDown"></fa-icon>
      </div>
      <div class="yan-btn create" [matMenuTriggerFor]="createMenu">
        <fa-icon [icon]="faPlus"></fa-icon>
      </div>
      <a href="https://{{storeCacheService.store.defaultDomainName}}" target="_blank"
         class="yan-btn white-btn view-site right-ic">
        <span>{{ 'COMMON.SHOPYAN_HEADER.VIEW_MY_STORE' | translate }}</span>
        <fa-icon [icon]="faExternalLink"></fa-icon>
      </a>
    </ng-container>

  </div>
  <div class="header-rp">
    <div class="more" [matMenuTriggerFor]="MoreMenu"></div>
    <a [routerLink]="['/account']" class="user-avatar">
      <span class="default-avatar">{{ avatar }}</span>
    </a>
    <div class="ics">
      <div class="language" [matMenuTriggerFor]="localeMenu">
        <div class="flag" *ngIf="selectedLocale">
          <span class="fi fi-{{selectedLocale.country | lowercase}}"></span>
        </div>
      </div>
      <div class="fullscreen" [class.full]="sfull.isFullscreen" (click)="fullScreenToggle()">
      </div>
      <div class="msgs" [matMenuTriggerFor]="MsgMenu">
        <div class="count">{{ messageCount }}</div>
      </div>
      <div class="notifs" [matMenuTriggerFor]="NotifMenu">
        <div class="count">{{ notifCount }}</div>
      </div>
    </div>
  </div>

</div>


<!-- MORE MENU -->
<mat-menu #MoreMenu>
  <div id="more-menu" class="yan-menu">
    <div class="yan-menu-padding">
      <a mat-menu-item [routerLink]="['/account']">{{ 'COMMON.SHOPYAN_HEADER.MY_ACCOUNT' | translate }}</a>
      <a
        *ngIf="showStoresMenu && storeCacheService.store"
        mat-menu-item href="https://{{storeCacheService.store.defaultDomainName}}"
        target="_blank">{{ 'COMMON.SHOPYAN_HEADER.VIEW_MY_STORE' | translate }}</a>
      <a mat-menu-item [routerLink]="['/my-sites']">{{ 'COMMON.SHOPYAN_HEADER.MY_STORES' | translate }}</a>
      <!--      <a mat-menu-item>{{ 'COMMON.SHOPYAN_HEADER.HELP_CENTER' | translate }}</a>-->
      <!--      <a mat-menu-item>{{ 'COMMON.SHOPYAN_HEADER.COMMUNITY' | translate }}</a>-->
      <div class="sep"></div>
      <a (click)="logout()" mat-menu-item>{{ 'COMMON.SHOPYAN_HEADER.LOG_OUT' | translate }}</a>
    </div>
  </div>
</mat-menu>

<!-- CREATE MENU -->
<mat-menu #createMenu>
  <div id="create-menu" class="yan-menu" *ngIf="storeCacheService.store">
    <a mat-menu-item
       *hasPermission="[featureType.PRODUCTS, featureAction.CREATE]"
       [routerLink]="['/store/'+storeCacheService.store.id+'/products/new']">{{ 'COMMON.SHOPYAN_HEADER.NEW_PRODUCT' | translate }}</a>
    <a mat-menu-item
       *hasPermission="[featureType.COLLECTIONS, featureAction.CREATE]"
       [routerLink]="['/store/'+storeCacheService.store.id+'/products/collections/new']">{{ 'COMMON.SHOPYAN_HEADER.NEW_COLLECTION' | translate }}</a>
    <a mat-menu-item
       *hasPermission="[featureType.ORDERS, featureAction.CREATE]"
       [routerLink]="['/store/'+storeCacheService.store.id+'/orders/new']">{{ 'COMMON.SHOPYAN_HEADER.NEW_ORDER' | translate }}</a>
    <a mat-menu-item
       *hasPermission="[featureType.DISCOUNTS, featureAction.CREATE]"
       [routerLink]="['/store/'+storeCacheService.store.id+'/discounts/new/PRODUCT']">{{ 'COMMON.SHOPYAN_HEADER.NEW_DISCOUNT' | translate }}</a>
    <a mat-menu-item
       *hasPermission="[featureType.CUSTOMERS, featureAction.CREATE]"
       [routerLink]="['/store/'+storeCacheService.store.id+'/customers/new']">{{ 'COMMON.SHOPYAN_HEADER.NEW_CUSTOMER' | translate }}</a>
  </div>
</mat-menu>

<!-- STORES MENU -->
<mat-menu #storesMenu>
  <div id="sites-menu" class="yan-menu" [dir]="direction">
    <div class="yan-menu-padding">
      <div class="yan-menu-ttl">
        <span>{{ 'COMMON.SHOPYAN_HEADER.MY_STORES' | translate }}</span>
        <a [routerLink]="['/site-form']" class="add">+ {{ 'COMMON.SHOPYAN_HEADER.CREATE_NEW_SITE' | translate }}</a>
      </div>
      <div style="position: relative;">
        <div class="site-filter" (click)="$event.stopPropagation()">
          <input type="text" class="yan-input" placeholder="{{ 'COMMON.SHOPYAN_HEADER.FIND_STORE' | translate }}"
                 [(ngModel)]="filter"
                 (keyup.enter)="loadStores()"
          />
        </div>
        <ngx-spinner name="stores-spinner" type="ball-clip-rotate"></ngx-spinner>
        <ng-container *ngIf="stores && stores.length > 0">
          <div class="sites" *ngIf="stores && stores.length > 0">
            <ng-container *ngFor="let store of stores">
              <div class="site-item" (click)="gotoStore(store.id)">
                <div class="site-name">{{ store.name }}</div>
                <div class="site-url">{{ store.defaultDomainName }}</div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="!stores || stores.length == 0">
          <app-no-result></app-no-result>
        </ng-container>
      </div>
    </div>
    <a [routerLink]="['/my-sites']" class="yan-menu-actions">
      {{ 'COMMON.SHOPYAN_HEADER.GO_TO_ALL_SITES' | translate }}
    </a>
  </div>
</mat-menu>

<!-- LOCALE MENU -->
<mat-menu #localeMenu>
  <div id="locale-menu" class="yan-menu">
    <div class="locale-item" mat-menu-item (click)="changeLanguage(locale.code)" *ngFor="let locale of locales">
      <div class="flag"><span class="fi fi-{{locale.country | lowercase}}"></span>
      </div>
      <span>{{ locale.name }}</span>
    </div>
  </div>
</mat-menu>

<!-- NOTIFICATIONS MENU -->
<mat-menu #NotifMenu>
  <div id="notifications">
    <ngx-spinner name="notifs-spinner" type="ball-clip-rotate"></ngx-spinner>
    <div class="n-item" *ngIf="!notifications || notifications.length === 0">
      <div class="notif-msg">
        {{'NOTIFICATION.EMPTY' | translate}}
      </div>
    </div>
    <div class="n-item" [class.seen]="data.seen" *ngFor="let data of notifications" (click)="gotoNotification(data)">
      <div class="type">
        <div class="new" *ngIf="!data.seen"></div>
        <div class="ic"></div>
      </div>
      <div class="notif-header" *ngIf="data.type === notificationType.ORDER">
        <div class="notif-type">{{'NOTIFICATION.' + data.type + '.TITLE' | translate:
          Object.assign({}, data.titleArgs.split(',')) }}</div>
        <div class="notif-date">{{(data.createdDate || now()) | date: 'dd/MM/yyyy HH:mm'}}</div>
      </div>
      <div class="notif-msg" [innerHTML]="'NOTIFICATION.' + data.type + '.MSG' | translate:
        Object.assign({}, data.msgArgs.split(','))"></div>
    </div>
    <div class="show-more yan-btn white-btn" *ngIf="notifShowMoreBtn" (click)="loadNotifications(true);$event.stopPropagation();">
      {{ 'COMMON.CUSTOMERS_MODAL.SHOW_MORE' | translate }}
    </div>
  </div>
</mat-menu>

<!-- MESSAGES MENU -->
<mat-menu #MsgMenu>
  <div id="messages">
    <ngx-spinner name="messages-spinner" type="ball-clip-rotate"></ngx-spinner>
    <div class="n-item" *ngIf="!messages || messages.length === 0">
      <div class="message-msg">
        {{'MESSAGE.EMPTY' | translate}}
      </div>
    </div>
    <div class="n-item" [class.seen]="data.seen" *ngFor="let data of messages" (click)="gotoMsg(data)">
      <div class="type">
        <div class="new" *ngIf="!data.seen"></div>
        <div class="ic"></div>
      </div>
      <div class="message-header">
        <div class="message-type">{{'MESSAGE.TITLE' | translate}}</div>
        <div class="message-date">{{(data.createdDate || now()) | date: 'dd/MM/yyyy HH:mm'}}</div>
      </div>
      <div class="message-msg" [innerHTML]="'MESSAGE.MSG' | translate:
        Object.assign({}, data.msgArgs.split(','))"></div>
    </div>
    <div class="show-more yan-btn white-btn" *ngIf="messagesShowMoreBtn" (click)="loadMessages(true);$event.stopPropagation();">
      {{ 'COMMON.CUSTOMERS_MODAL.SHOW_MORE' | translate }}
    </div>
  </div>
</mat-menu>
