import {Address, CustomerNote, Tag} from '@app/_shared';
import {CustomerStatusEnum} from "@app/_shared/enumeration/customer-status.enum";
import {CustomerTimeLineItem} from "@app/_shared/model/customer/customer-timeline.model";

export class Customer {
  id: string;
  firstName: string;
  lastName?: string;
  email: string;
  phoneNumber?: string;
  createdDate?: string;
  status?: CustomerStatusEnum;
  location?: string;
  totalOrders?: number;
  totalSpent?: number;
  deleted: boolean;
}

export class CustomerAbstract {
  id: string;
  firstName: string;
  lastName?: string;
  email: string;
  phoneNumber?: string;
  marketingEmails?: boolean;
  addresses: Address[];
  tags: Tag[];
  notes: CustomerNote[];
}

export class CustomerForm extends CustomerAbstract {
}

export class CustomerDetail extends CustomerAbstract {
  username?: string;
  createdDate?: string;
  orders?: string[];
  totalSpent: number;
  timeLineItems: CustomerTimeLineItem[];
  status?: CustomerStatusEnum;
}

export class CustomerOverview {
  id: string;
  firstName: string;
  lastName?: string;
  email: string;
  phoneNumber: string;
  createdDate?: string;
  addresses: Address[];
  location: string;
}

