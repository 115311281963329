export const environment = {
  production: true,
  conf: {
    paypal: true,
    payzone: true,
    stripeConnect: true,
    team: true,
    cloudflare: true
  },
  domainUrl: 'myshopyan.com',
  contextPath: 'https://seller.shopyan.com',
  productApiUrl: 'https://api.svc.shopyan.com/products/admin/api',
  mediaURL: 'https://api.svc.shopyan.com/products/media/',
  storeApiUrl: 'https://api.svc.shopyan.com/store/admin/api',
  publicStoreApiUrl: 'https://api.svc.myshopyan.com/store',
  discountApiUrl: 'https://api.svc.shopyan.com/discounts/admin/api',
  orderApiUrl: 'https://api.svc.shopyan.com/orders/admin/api',
  socketUrl: 'wss://api.svc.shopyan.com/ws/websocket',
  builderUrl: 'https://builder.shopyan.com',
  storeURL: 'https://store.shopyan.com',
  payzoneUrl: 'https://payment.payzone.ma/pwthree/launch',
  keycloak: {
    // Url of the Identity Provider
    url: 'https://auth.svc.shopyan.com/auth',
    realm: 'shopyan-auth',
    clientId: 'shopyan-client',
  },
  googleApisCredentials: {
    client_id: '706320774103-o10qio5f115plql13jkocm9lk09krf5h.apps.googleusercontent.com',
    callback: 'https://seller.shopyan.com/callback'
  }
}
