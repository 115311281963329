import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {Pageable} from "@app/_shared";

@Injectable({providedIn: 'root'})
export class SupplierService {

  constructor(private http: HttpClient) {
  }


  /**
   * Get all suppliers
   */
  public getAll(pageable?: Pageable, searchText?: string): Observable<any> {

    const url = `${environment.productApiUrl}/suppliers`;
    let params = pageable ? pageable.getParams() : new HttpParams();
    if (searchText) {
      params = params.append('searchText', searchText);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * Delete a Supplier
   * @param supplierId
   */
  public deleteSupplier(supplierId: string): Observable<any> {
    let url = `${environment.productApiUrl}/suppliers/${supplierId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.delete(url, httpOptions);
  }

}

