
export class Address {
  id?: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  defaultAddress: boolean;
}
