import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Pageable} from '@app/_shared';

@Injectable({providedIn: 'root'})
export class CollectionService {

  constructor(private http: HttpClient) {
  }


  /**
   * Filter collections
   * @param pageable
   * @param searchText
   */
  public getAll(pageable?: Pageable, searchText?: string): Observable<any> {

    const url = `${environment.productApiUrl}/collections`;
    let params = pageable ? pageable.getParams() : new HttpParams();
    if (searchText) {
      params = params.append('searchText', searchText);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * Disable/Enable a collection
   * @param collectionID
   * @param available
   */
  public updateCollectionAvailability(collectionID: string, available: boolean): Observable<any> {
    let url = `${environment.productApiUrl}/collections/availability/${collectionID}/?availability=${available}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.patch(url, httpOptions);
  }


  /**
   * Delete a collection
   * @param collectionID
   */
  public deleteCollection(collectionID: string): Observable<any> {
    let url = `${environment.productApiUrl}/collections/${collectionID}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.delete(url, httpOptions);
  }


}

