export const timezones = [
    {
        'olsonName': 'Etc/GMT+12',
        'description': '(GMT-12:00) Ligne de date internationale Ouest'
    },
    {
        'olsonName': 'Pacific/Pago_Pago',
        'description': '(GMT-11:00) Samoa américaines'
    },
    {
        'olsonName': 'Pacific/Midway',
        'description': '(GMT-11:00) Île Midway'
    },
    {
        'olsonName': 'Pacific/Honolulu',
        'description': '(GMT-10:00) Hawaï'
    },
    {
        'olsonName': 'America/Juneau',
        'description': '(GMT -09:00) Alaska'
    },
    {
        'olsonName': 'America/Los_Angeles',
        'description': '(GMT-08:00) Heure du Pacifique (États-Unis et Canada)'
    },
    {
        'olsonName': 'America/Tijuana',
        'description': '(GMT -08:00) Tijuana'
    },
    {
        'olsonName': 'America/Phoenix',
        'description': '(GMT-07:00) Arizona'
    },
    {
        'olsonName': 'America/Mazatlan',
        'description': '(GMT -07:00) Mazatlan'
    },
    {
        'olsonName': 'America/Denver',
        'description': '(GMT -07:00) Heure des Rocheuses (États-Unis et Canada)'
    },
    {
        'olsonName': 'America/Guatemala',
        'description': '(GMT -06:00) Amérique centrale'
    },
    {
        'olsonName': 'America/Chicago',
        'description': '(GMT -06:00) Heure centrale (États-Unis et Canada)'
    },
    {
        'olsonName': 'America/Chihuahua',
        'description': '(GMT -07:00) Chihuahua'
    },
    {
        'olsonName': 'America/Mexico_City',
        'description': '(GMT -06:00) Guadalajara, Mexico'
    },
    {
        'olsonName': 'America/Monterrey',
        'description': '(GMT -06:00) Monterrey'
    },
    {
        'olsonName': 'America/Regina',
        'description': '(GMT -06:00) Saskatchewan'
    },
    {
        'olsonName': 'America/Bogota',
        'description': '(GMT -05:00) Bogota'
    },
    {
        'olsonName': 'America/New_York',
        'description': '(GMT -05:00) Heure de l\'Est (États-Unis et Canada)'
    },
    {
        'olsonName': 'America/Indiana/Indianapolis',
        'description': '(GMT -05:00) Indiana (Est)'
    },
    {
        'olsonName': 'America/Lima',
        'description': '(GMT -05:00) Lima, Quito'
    },
    {
        'olsonName': 'America/Halifax',
        'description': '(GMT -04:00) Heure de l\'Atlantique (Canada)'
    },
    {
        'olsonName': 'America/Caracas',
        'description': '(GMT -04:00) Caracas'
    },
    {
        'olsonName': 'America/Guyana',
        'description': '(GMT -04:00) Georgetown'
    },
    {
        'olsonName': 'America/La_Paz',
        'description': '(GMT -04:00) La Paz'
    },
    {
        'olsonName': 'America/Puerto_Rico',
        'description': '(GMT -04:00) Porto Rico'
    },
    {
        'olsonName': 'America/Santiago',
        'description': '(GMT -04:00) Santiago'
    },
    {
        'olsonName': 'America/St_Johns',
        'description': '(GMT -03:30) Terre-Neuve'
    },
    {
        'olsonName': 'America/Sao_Paulo',
        'description': '(GMT -03:00) Brasilia'
    },
    {
        'olsonName': 'America/Argentina/Buenos_Aires',
        'description': '(GMT -03:00) Buenos Aires'
    },
    {
        'olsonName': 'America/Godthab',
        'description': '(GMT -03:00) Groenland'
    },
    {
        'olsonName': 'America/Montevideo',
        'description': '(GMT -03:00) Montevideo'
    },
    {
        'olsonName': 'Atlantic/South_Georgia',
        'description': '(GMT -02:00) Mid-Atlantic'
    },
    {
        'olsonName': 'Atlantic/Azores',
        'description': '(GMT -01:00) Açores'
    },
    {
        'olsonName': 'Atlantic/Cape_Verde',
        'description': '(GMT -01:00) Île- du Cap Vert'
    },
    {
        'olsonName': 'Europe/London',
        'description': '(GMT +00:00) Édimbourg, Londres'
    },
    {
        'olsonName': 'Europe/Lisbon',
        'description': '(GMT +00:00) Lisbonne'
    },
    {
        'olsonName': 'Africa/Monrovia',
        'description': '(GMT +00:00) Monrovia'
    },
    {
        'olsonName': 'Etc/UTC',
        'description': '(GMT +00:00) UTC'
    },
    {
        'olsonName': 'Europe/Amsterdam',
        'description': '(GMT +01:00) Amsterdam'
    },
    {
        'olsonName': 'Europe/Belgrade',
        'description': '(GMT +01:00) Belgrade'
    },
    {
        'olsonName': 'Europe/Berlin',
        'description': '(GMT +01:00) Berlin'
    },
    {
        'olsonName': 'Europe/Zurich',
        'description': '(GMT +01:00) Berne, Zurich'
    },
    {
        'olsonName': 'Europe/Bratislava',
        'description': '(GMT +01:00) Bratislava'
    },
    {
        'olsonName': 'Europe/Brussels',
        'description': '(GMT +01:00) Bruxelles'
    },
    {
        'olsonName': 'Europe/Budapest',
        'description': '(GMT +01:00) Budapest'
    },
    {
        'olsonName': 'Africa/Casablanca',
        'description': '(GMT+01:00) Casablanca'
    },
    {
        'olsonName': 'Europe/Copenhagen',
        'description': '(GMT +01:00) Copenhague'
    },
    {
        'olsonName': 'Europe/Dublin',
        'description': '(GMT +00:00) Dublin'
    },
    {
        'olsonName': 'Europe/Ljubljana',
        'description': '(GMT +01:00) Ljubljana'
    },
    {
        'olsonName': 'Europe/Madrid',
        'description': '(GMT +01:00) Madrid'
    },
    {
        'olsonName': 'Europe/Paris',
        'description': '(GMT +01:00) Paris'
    },
    {
        'olsonName': 'Europe/Prague',
        'description': '(GMT +01:00) Prague'
    },
    {
        'olsonName': 'Europe/Rome',
        'description': '(GMT +01:00) Rome'
    },
    {
        'olsonName': 'Europe/Sarajevo',
        'description': '(GMT +01:00) Sarajevo'
    },
    {
        'olsonName': 'Europe/Skopje',
        'description': '(GMT +01:00) Skopje'
    },
    {
        'olsonName': 'Europe/Stockholm',
        'description': '(GMT +01:00) Stockholm'
    },
    {
        'olsonName': 'Europe/Vienna',
        'description': '(GMT +01:00) Vienne'
    },
    {
        'olsonName': 'Europe/Warsaw',
        'description': '(GMT +01:00) Varsovie'
    },
    {
        'olsonName': 'Africa/Algiers',
        'description': '(GMT +01:00) Afrique centrale et de l\'Ouest'
    },
    {
        'olsonName': 'Europe/Zagreb',
        'description': '(GMT +01:00) Zagreb'
    },
    {
        'olsonName': 'Europe/Athens',
        'description': '(GMT +02:00) Athènes'
    },
    {
        'olsonName': 'Europe/Bucharest',
        'description': '(GMT +02:00) Bucarest'
    },
    {
        'olsonName': 'Africa/Cairo',
        'description': '(GMT +02:00) Le Caire'
    },
    {
        'olsonName': 'Africa/Harare',
        'description': '(GMT +02:00) Harare'
    },
    {
        'olsonName': 'Europe/Helsinki',
        'description': '(GMT +02:00) Helsinki'
    },
    {
        'olsonName': 'Asia/Jerusalem',
        'description': '(GMT +02:00) Jérusalem'
    },
    {
        'olsonName': 'Europe/Kaliningrad',
        'description': '(GMT +02:00) Kaliningrad'
    },
    {
        'olsonName': 'Europe/Kiev',
        'description': '(GMT +02:00) Kiev'
    },
    {
        'olsonName': 'Africa/Johannesburg',
        'description': '(GMT +02:00) Pretoria'
    },
    {
        'olsonName': 'Europe/Riga',
        'description': '(GMT +02:00) Riga'
    },
    {
        'olsonName': 'Europe/Sofia',
        'description': '(GMT +02:00) Sofia'
    },
    {
        'olsonName': 'Europe/Tallinn',
        'description': '(GMT +02:00) Tallinn'
    },
    {
        'olsonName': 'Europe/Vilnius',
        'description': '(GMT +02:00) Vilnius'
    },
    {
        'olsonName': 'Asia/Baghdad',
        'description': '(GMT +03:00) Bagdad'
    },
    {
        'olsonName': 'Europe/Istanbul',
        'description': '(GMT +03:00) Istanbul'
    },
    {
        'olsonName': 'Asia/Kuwait',
        'description': '(GMT +03:00) Koweït'
    },
    {
        'olsonName': 'Europe/Minsk',
        'description': '(GMT +03:00) Minsk'
    },
    {
        'olsonName': 'Europe/Moscow',
        'description': '(GMT +03:00) Moscou, Saint-Pétersbourg'
    },
    {
        'olsonName': 'Africa/Nairobi',
        'description': '(GMT +03:00) Nairobi'
    },
    {
        'olsonName': 'Asia/Riyadh',
        'description': '(GMT +03:00) Riyad'
    },
    {
        'olsonName': 'Europe/Volgograd',
        'description': '(GMT +03:00) Volgograd'
    },
    {
        'olsonName': 'Asia/Tehran',
        'description': '(GMT +03:30) Téhéran'
    },
    {
        'olsonName': 'Asia/Muscat',
        'description': '(GMT +04:00) Abu Dhabi, Mascate'
    },
    {
        'olsonName': 'Asia/Baku',
        'description': '(GMT +04:00) Bakou'
    },
    {
        'olsonName': 'Europe/Samara',
        'description': '(GMT +04:00) Samara'
    },
    {
        'olsonName': 'Asia/Tbilisi',
        'description': '(GMT +04:00) Tbilissi'
    },
    {
        'olsonName': 'Asia/Yerevan',
        'description': '(GMT +04:00) Erevan'
    },
    {
        'olsonName': 'Asia/Kabul',
        'description': '(GMT +04:30) Kaboul'
    },
    {
        'olsonName': 'Asia/Yekaterinburg',
        'description': '(GMT +05:00) Ekaterinbourg'
    },
    {
        'olsonName': 'Asia/Karachi',
        'description': '(GMT +05:00) Islamabad, Karachi'
    },
    {
        'olsonName': 'Asia/Tashkent',
        'description': '(GMT +05:00) Tachkent'
    },
    {
        'olsonName': 'Asia/Kolkata',
        'description': '(GMT +05:30) Chennai, Kolkata, Mumbai, New Delhi'
    },
    {
        'olsonName': 'Asia/Colombo',
        'description': '(GMT +05:30) Sri Jayawardenepura'
    },
    {
        'olsonName': 'Asia/Kathmandu',
        'description': '(GMT +05:45) Katmandou'
    },
    {
        'olsonName': 'Asia/Almaty',
        'description': '(GMT +06:00) Almaty'
    },
    {
        'olsonName': 'Asia/Dhaka',
        'description': '(GMT +06:00) Astana, Dhaka'
    },
    {
        'olsonName': 'Asia/Urumqi',
        'description': '(GMT +06:00) Urumqi'
    },
    {
        'olsonName': 'Asia/Rangoon',
        'description': '(GMT +06:30) Rangoon'
    },
    {
        'olsonName': 'Asia/Bangkok',
        'description': '(GMT +07:00) Bangkok, Hanoï'
    },
    {
        'olsonName': 'Asia/Jakarta',
        'description': '(GMT + 07:00) Jakarta'
    },
    {
        'olsonName': 'Asia/Krasnoyarsk',
        'description': '(GMT + 07:00) Krasnoyarsk'
    },
    {
        'olsonName': 'Asia/Novosibirsk',
        'description': '(GMT + 07:00) Novossibirsk'
    },
    {
        'olsonName': 'Asia/Shanghai',
        'description': '(GMT + 08:00) Beijing'
    },
    {
        'olsonName': 'Asia/Chongqing',
        'description': '(GMT + 08:00) Chongqing'
    },
    {
        'olsonName': 'Asia/Hong_Kong',
        'description': '(GMT + 08:00) Hong Kong'
    },
    {
        'olsonName': 'Asia/Irkutsk',
        'description': '(GMT + 08:00) Irkoutsk'
    },
    {
        'olsonName': 'Asia/Kuala_Lumpur',
        'description': '(GMT + 08:00) Kuala Lumpur'
    },
    {
        'olsonName': 'Australia/Perth',
        'description': '(GMT + 08:00) Perth'
    },
    {
        'olsonName': 'Asia/Singapore',
        'description': '(GMT + 08:00) Singapour'
    },
    {
        'olsonName': 'Asia/Taipei',
        'description': '(GMT + 08:00) Taipei'
    },
    {
        'olsonName': 'Asia/Ulaanbaatar',
        'description': '(GMT + 08:00) Ulaanbaatar'
    },
    {
        'olsonName': 'Asia/Tokyo',
        'description': '(GMT + 09:00) Osaka, Sapporo, Tokyo'
    },
    {
        'olsonName': 'Asia/Seoul',
        'description': '(GMT + 09:00) Séoul'
    },
    {
        'olsonName': 'Asia/Yakutsk',
        'description': '(GMT + 09:00) Yakutsk'
    },
    {
        'olsonName': 'Australia/Adelaide',
        'description': '(GMT + 09:30) Adélaïde'
    },
    {
        'olsonName': 'Australia/Darwin',
        'description': '(GMT + 09:30) Darwin'
    },
    {
        'olsonName': 'Australia/Brisbane',
        'description': '(GMT + 10:00) Brisbane'
    },
    {
        'olsonName': 'Australia/Melbourne',
        'description': '(GMT + 10:00) Canberra, Melbourne'
    },
    {
        'olsonName': 'Pacific/Guam',
        'description': '(GMT + 10:00) Guam'
    },
    {
        'olsonName': 'Australia/Hobart',
        'description': '(GMT + 10:00) Hobart'
    },
    {
        'olsonName': 'Pacific/Port_Moresby',
        'description': '(GMT + 10:00) Port Moresby'
    },
    {
        'olsonName': 'Australia/Sydney',
        'description': '(GMT + 10:00) Sydney'
    },
    {
        'olsonName': 'Asia/Vladivostok',
        'description': '(GMT + 10:00) Vladivostok'
    },
    {
        'olsonName': 'Asia/Magadan',
        'description': '(GMT + 11h00) Magadan'
    },
    {
        'olsonName': 'Pacific/Noumea',
        'description': '(GMT + 11:00) Nouvelle-Calédonie'
    },
    {
        'olsonName': 'Pacific/Guadalcanal',
        'description': '(GMT + 11:00) Salomon Is.'
    },
    {
        'olsonName': 'Asia/Srednekolymsk',
        'description': '(GMT + 11:00) Srednekolymsk'
    },
    {
        'olsonName': 'Pacific/Auckland',
        'description': '(GMT + 12:00) Auckland, Wellington'
    },
    {
        'olsonName': 'Pacific/Fiji',
        'description': '(GMT + 12:00) Fidji'
    },
    {
        'olsonName': 'Asia/Kamchatka',
        'description': '(GMT + 12: 00) Kamchatka'
    },
    {
        'olsonName': 'Pacific/Majuro',
        'description': '(GMT + 12: 00) Marshall Is.'
    },
    {
        'olsonName': 'Pacific/Chatham',
        'description': '(GMT +12:45) Îles Chatham'
    },
    {
        'olsonName': 'Pacific/Tongatapu',
        'description': '(GMT +13:00) Nuku\'alofa'
    },
    {
        'olsonName': 'Pacific/Apia',
        'description': '(GMT +13:00) Samoa'
    },
    {
        'olsonName': 'Pacific/Fakaofo',
        'description': '(GMT +13:00) Îles Tokélaou'
    }
];
