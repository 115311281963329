import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Pageable} from "@app/_shared";
import {catchError} from "rxjs/operators";
import {KeycloakService} from "keycloak-angular";
import {urlencoded} from "express";

@Injectable({providedIn: 'root'})
export class UserService {

    constructor(private http: HttpClient, private keycloakService: KeycloakService) {
    }

    /**
     * Logout from session
     */
    public isEmailVerified(): Observable<any> {
      let url = `${environment.storeApiUrl}/user/email-verified`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

      return this.http.get(url, httpOptions);
    }

    /**
     * Logout from session
     */
    public sendVerifyEmail(): Observable<any> {
      let url = `${environment.storeApiUrl}/user/verification-email`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

      return this.http.post(url, httpOptions);
    }

    public getLang(): Observable<any> {
      let url = `${environment.storeApiUrl}/user/lang`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ignoreError': 'true'
        }),
        responseType: 'text' as 'json'
      };

      return this.http.get(url, httpOptions);
    }

    public updateLang(code: string): Observable<any> {
      let url = `${environment.storeApiUrl}/user/lang/${code}`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
      };

      return this.http.put(url, httpOptions);
    }

    /**
     * Logout from session
     */
    public logout(): Observable<any> {
        let url = `${environment.storeApiUrl}/user/logout`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http.post(url, httpOptions);
    }

  public getNotifications(pageable: Pageable): Observable<any> {
    let url = `${environment.storeApiUrl}/user/notifications`;
    let params = pageable ? pageable.getParams() : new HttpParams();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  public countUnSeenNotification(): Observable<any> {
    let url = `${environment.storeApiUrl}/user/notifications/unseen`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  public markNotificationAsSeen(id:string): Observable<any> {
    let url = `${environment.storeApiUrl}/user/notifications/seen/${id}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }


  public getMessages(pageable: Pageable): Observable<any> {
    let url = `${environment.storeApiUrl}/user/messages`;
    let params = pageable ? pageable.getParams() : new HttpParams();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  public countUnSeenMessages(): Observable<any> {
    let url = `${environment.storeApiUrl}/user/messages/unseen`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  public markMessageAsSeen(id:string): Observable<any> {
    let url = `${environment.storeApiUrl}/user/messages/seen/${id}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  getMessage(id: string) {
    let url = `${environment.storeApiUrl}/user/messages/${id}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  getNewVersion() {
    let url = `${environment.storeApiUrl}/versions/newVersion`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  markAsSeenNewVersion() {
    let url = `${environment.storeApiUrl}/versions/seen`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.put(url, null, httpOptions);
  }

  linkSocialAccount(provider: string): Observable<any> {
    let url = `${environment.storeApiUrl}/user/social-link/${provider}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'text' as 'json'
    };
    return this.http.get(url, httpOptions);
  }

  getAccessHistory() {
    var url = this.getRealmUrl() + '/account/sessions/devices';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.keycloakService.getKeycloakInstance().token
      })
    };
    return this.http.get(url, httpOptions);
  }

  removeSession(id: string) {
    const url = `${environment.storeApiUrl}/user/sessions/${id}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.delete(url, httpOptions);
  }

  getRealmUrl() {
    const authServerUrl = this.keycloakService.getKeycloakInstance().authServerUrl;
    if (typeof authServerUrl !== 'undefined') {
      if (authServerUrl.charAt(authServerUrl.length - 1) == '/') {
        return authServerUrl + 'realms/' + environment.keycloak.realm;
      } else {
        return authServerUrl + '/realms/' + environment.keycloak.realm;
      }
    } else {
      return undefined;
    }
  }

  getProfile() {
    const url = `${environment.storeApiUrl}/user/profile`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  getAccountSecurity() {
    const url = `${environment.storeApiUrl}/user/account-security`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  updateProfile(profile: any) {
    const url = `${environment.storeApiUrl}/user/profile`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'text' as 'json'
    };
    return this.http.put(url, profile, httpOptions);
  }

  removeOtp(id: string) {
    const url = `${environment.storeApiUrl}/user/otp/${id}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.delete(url, httpOptions);
  }

  removeSocial(id: string) {
    const url = `${environment.storeApiUrl}/user/social/${id}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.delete(url, httpOptions);
  }
}

