import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Currency} from '@app/_shared';
import {PermissionStore} from "@app/_shared/model/permission-store.model";
import {StoreCache} from "@app/_shared/model/store/store-cache.model";
import {PlanFeature} from "@app/_shared/model/plan/plan-feature.model";
import {Store} from "@app/_shared/model/store/store.model";
import {SubscriptionStatus} from "@app/_shared/enumeration/subscription-status.enum";
import {PlanType} from "@app/_shared/model/plan/plan-type.model";

@Injectable({providedIn: 'root'})
export class StoreCacheService {

  currency: Currency;
  store: Store;
  storePermissions: PermissionStore;
  planFeatures: PlanFeature[];
  employee = false;
  isDisabledByAdmin = false;
  fromStore = false;
  initialized = false;
  subscriptionStatus: SubscriptionStatus;
  planType: PlanType;

  constructor(private http: HttpClient) {
  }


  /**
   * Init store cache data
   */
  public initStoreCacheData(data: StoreCache) {
    this.currency = data.currency;
    this.storePermissions = data.storePermissions;
    this.employee = data.employee;
    this.isDisabledByAdmin = data.store.disabledByAdmin;
    this.planFeatures = data.planFeatures;
    this.store = data.store;
    this.subscriptionStatus = data.subscriptionStatus;
    this.planType = data.planType;
    this.initialized = true;
  }


  /**
   * Get store cache data
   */
  public getStoreCacheData(id: string): Observable<any> {
    let url = `${environment.storeApiUrl}/store/cache`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'storeId': id,
      })
    };
    return this.http.get(url, httpOptions);
  }

}

