import {Brand, Collection, PeriodType, Supplier} from '@app/_shared';

export class ProductFilter {
    text: string;
    available?: boolean;
    archived?: boolean;
    period?: PeriodType;
    collections: Collection[] = [];
    brands: Brand[] = [];
    suppliers: Supplier[] = [];
}
