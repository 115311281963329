import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {
  MediaSize,
  MediaType,
  Pageable,
  Product,
  ProductService,
  ProductVariation,
  YanLoaderService
} from '@app/_shared';
import {SelectionModel} from '@angular/cdk/collections';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {faImage} from '@fortawesome/free-solid-svg-icons';
import {StoreCacheService} from '@app/_shared/service/store-cache.service';
import {urlMedia} from '@app/_shared/helper/media-helper';

@Component({
  selector: 'app-products-modal',
  templateUrl: './products-modal.component.html',
  styleUrls: ['./products-modal.component.scss']
})
export class ProductsModalComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();

  pageable = new Pageable();
  filter: string;
  displayShowMore = false;

  products: Product[] = [];
  productSelection = new SelectionModel<any>(true, []);
  variantSelection = new SelectionModel<any>(true, []);

  productPreSelection = new SelectionModel<any>(true, []);
  variantPreSelection = new SelectionModel<any>(true, []);

  faImage = faImage;

  currency = '';

  constructor(public dialogRef: MatDialogRef<ProductsModalComponent>,
              @Inject(MAT_DIALOG_DATA)
              public data: any,
              private storeCacheService: StoreCacheService,
              private productService: ProductService,
              private yanLoaderService: YanLoaderService) {
  }


  ngOnInit(): void {
    this.pageable.size = 10;
    this.data?.products?.forEach((item: Product) => {
      if (this.data?.expanded && item.variations?.length > 0) {
        item.variations.forEach(variation => {
          this.variantSelection.select(variation.id);
          this.variantPreSelection.select(variation.id);
        });
      } else {
        this.productSelection.select(item.id);
        this.productPreSelection.select(item.id);
      }
    });
    this.currency = this.storeCacheService.currency.symbole;
    this.filterProducts();
  }

  /**
   * Input search changed
   */
  search(): void {
    this.pageable.page = 1;
    this.filterProducts();
  }

  /**
   * Filter products
   */
  filterProducts(showMore?: boolean): void {
    this.yanLoaderService.startLoader('modal-spinner');
    // TODO specify : get variant or not
    const sub = this.productService.filterShortListProducts(this.filter, this.pageable).subscribe(response => {
      if (!showMore) {
        this.products = [...response];
      } else {
        this.products = [...this.products, ...response];
      }
      this.displayShowMore = response?.length === this.pageable.size;
      this.yanLoaderService.stopLoader('modal-spinner');
    });
    this.subscription.add(sub);
  }

  masterToggle(product: Product) {
    this.productSelection.toggle(product.id);

    product.variations?.forEach((variation: ProductVariation) => {
      if (this.productSelection.isSelected(product.id)) {
        this.variantSelection.select(variation.id!);
      } else {
        this.variantSelection.deselect(variation.id!);
      }
    });
  }

  /**
   * Show more products
   */
  showMore(): void {
    this.pageable.page++;
    this.filterProducts(true);
  }

  /**
   * Submit selection
   */
  submit() {

    const selectedProducts: Product[] = [];

    this.productSelection.selected.forEach(item => {
      const p = this.products.find(p => p.id === item);
      if (p) {
        const tmpP = {...p};
        tmpP.variations = [];
        selectedProducts.push(tmpP);
      }
    });
    if (this.data.expanded) {
      this.variantSelection.selected.forEach(item => {
        this.products.forEach(product => {
          const variation = product.variations?.find(v => v.id === item);
          if (variation) {
            const p = selectedProducts.find(p => p.id === product.id);
            if (p) {
              p.variations.push(variation);
            } else {
              const newP = {...product};
              newP.variations = [];
              newP.variations.push(variation);
              selectedProducts.push(newP);
            }
          }
        })
      });
    }

    this.dialogRef.close(selectedProducts);
  }


  /**
   *Close modal
   */
  closeModal(): void {
    this.dialogRef.close();
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  urlSmallProductMedia(media: string): string {
    return urlMedia(MediaType.PRODUCT, MediaSize.small, media);
  }

  urlSmallVariantMedia(media: string): string {
    return urlMedia(MediaType.VARIANT, MediaSize.small, media);
  }

}
