import { Product, UpsellPage } from '@app/_shared';

export abstract class AbstractUpsell {
    id: string;
    label: string;
    active: boolean;
    discountPercent: number;
    fixedDiscount: number;
    timer: number;
    pages: UpsellPage[];
    description: string;
}

export class Upsell extends AbstractUpsell {
    triggerProducts: Product[];
    offerProducts: Product[];
    views: number;
    conversion: number;
    createdDate: Date;
}

export class UpsellForm extends AbstractUpsell {
    triggerProducts: UpsellProductVariationForm[];
    offerProducts: UpsellProductVariationForm[];
}

export class UpsellProductVariationForm {
    product: string;
    variations: string[];
}
