import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router'
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular'

@Injectable({ providedIn: 'root' })
export class AppAuthGuard extends KeycloakAuthGuard {

    constructor(protected router: Router, protected keycloakService: KeycloakService) {
        super(router, keycloakService)
    }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
      // Force the user to log in if currently unauthenticated.
      if (!this.authenticated) {
        await this.keycloakService.login({
          redirectUri: window.location.origin + state.url
        });
      }
      return true;
      // // Get the roles required from the route.
      // const requiredRoles = route.data.roles;
      //
      // // Allow the user to proceed if no additional roles are required to access the route.
      // if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      //   return true;
      // }
      //
      // // Allow the user to proceed if one of required roles is present.
      // return requiredRoles.some((role) => this.roles.includes(role));
  }
}
