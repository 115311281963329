import {OrderStatus, PaymentStatus, PeriodType} from '@app/_shared';

export class OrderFilter {
  text: string;
  idCustomer?: string;
  status?: OrderStatus;
  paymentStatus?: PaymentStatus;
  period?: PeriodType;
  startDate?: Date;
  endDate?: Date;
  minAmount?: number;
  maxAmount?: number;
  archived: boolean;
  product?: string;
}

export class OrderInvoiceFilter {
  text: string;
}
