import {
  Brand,
  Collection,
  DimensionUnit,
  ProductOption,
  ProductReview,
  ProductVariation,
  Seo,
  Supplier,
  VolumeUnit,
  WeightUnit
} from '@app/_shared';

export abstract class AbstractProduct {
  id?: string;
  code?: string;
  name: string;
  description: string;
  available: boolean;
  pricing: ProductPrice;
  seo: Seo;
  shipping: ProductShipping;
  options: ProductOption[];
  variations: ProductVariation[];
  sku: string;
  barCode: string;
  stock: number;
  disableOutOfStock: boolean;
  tags: string[];
  imageLinks: string[];
  youtubeIds: string[];
  medias: string[];
  slug: string;
}

export class ProductForm extends AbstractProduct {
  collections: string[];
  supplier: string;
  brand: string;
  reviews: ProductReview[];
  relatedProducts: string[];
}

export class Product extends AbstractProduct {
  collections: Collection[];
  supplier: Supplier;
  brand: Brand;
  totalIncome: number;
  totalOrders: number;
  createdDate: Date;
  rate: number;
  rateCount: number;
  archived: boolean;
  relatedProducts: Product[];
}

export class ProductPrice {
  price: number;
  basicPrice: number;
}

export class ProductShipping {
  id: string;
  weight: number;
  volume: number;
  width: number;
  height: number;
  depth: number;
  weightUnit: WeightUnit;
  volumeUnit: VolumeUnit;
  dimensionUnit: DimensionUnit;
}

export class ProductRate {
  rate: number;
  counter: number;
}

export class ProductOverview {
  id?: string;
  name: string;
  available: boolean;
  price: number;
  media: string;
  imageLink: string;
  totalIncome: number;
  totalOrders: number;
}
