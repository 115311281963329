import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Pageable, UpsellFilter } from '@app/_shared';

@Injectable({providedIn: 'root'})
export class UpsellService {

    constructor(private http: HttpClient) {
    }


    /**
     * Filter upsells
     * @param storeId
     * @param filter
     * @param pageable
     */
    public filterUpsells(storeId: string, filter: UpsellFilter, pageable: Pageable): Observable<any> {
        let url = `${environment.productApiUrl}/upsells/filter`;
        let params = pageable ? pageable.getParams() : new HttpParams();
        if (filter?.text) {
            params = params.append('text', filter.text);
        }

        if (filter?.active === true) {
            params = params.append('active', true);
        }

        if (filter?.active === false) {
            params = params.append('active', false);
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: params
        };
        return this.http.get(url, httpOptions);

    }

    /**
     * Delete upsells
     * @param storeId
     * @param ids
     */
    public deleteUpsells(ids: string[]): Observable<any> {
        let url = `${environment.productApiUrl}/upsells`;
        let params = new HttpParams();
        params = params.append('ids', ids.join(','));
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: params
        };

        return this.http.delete(url, httpOptions);
    }


    /**
     * Enable/Disable upsells
     * @param ids
     * @param availability
     */
    public updateAvailability(ids: string[], availability: boolean): Observable<any> {
        let url = `${environment.productApiUrl}/upsells/availability`;
        let params = new HttpParams();
        params = params.append('ids', ids.join(','));
        params = params.append('active', availability);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: params
        };

        return this.http.patch(url, null, httpOptions);
    }

    /**
     * duplicate upsell
     * @param id
     */
    public duplicate(id: string): Observable<any> {
        let url = `${environment.productApiUrl}/upsells/duplicate/${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            responseType: 'text' as 'json',
        };
        return this.http.get(url, httpOptions);
    }
}

