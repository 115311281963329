import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {StorePaymentsSettings} from "@app/_shared/model/store/store-payments-settings.model";
import {Pageable, PlanCheckout, PlanInvoiceType} from "@app/_shared";
import {StoreCurrencyLang} from "@app/_shared/model/store/store-currency-lang.model";
import {StoreSeo} from "@app/_shared/model/store/store-seo.model";

@Injectable({providedIn: 'root'})
export class StoreSettingsService {

    constructor(private http: HttpClient) {
    }

    /**
     * get store settings
     * @param storeID store id
     */
    public getStore(storeID: string): Observable<any> {
        let url = `${environment.storeApiUrl}/settings/general`;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get(url, httpOptions);
    }

    /**
     * get paypal connect url
     */
    public getPaypalConnectUrl(): Observable<any> {
      let url = `${environment.orderApiUrl}/settings/payments/paypalConnect`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: 'text' as 'json'
      };
      return this.http.get(url, httpOptions);
    }

    /**
     * get Stripe connect url
     */
    public getStripeConnectUrl(): Observable<any> {
      let url = `${environment.orderApiUrl}/settings/payments/stripeConnect`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: 'text' as 'json'
      };
      return this.http.get(url, httpOptions);
    }

    /**
     * connect Stripe Account
     * @param data data
     */
    public connectStripeAccount(data: any, storeId: string): Observable<any> {
      let url = `${environment.orderApiUrl}/settings/payments/stripeConnect`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'storeId': storeId
        })
      };
      return this.http.post(url, data, httpOptions);
    }

    /**
     * connect paypal Account
     * @param data data
     */
    public connectPaypalAccount(data: any, storeId: string): Observable<any> {
      let url = `${environment.orderApiUrl}/settings/payments/paypalConnect`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'storeId': storeId
        })
      };
      return this.http.post(url, data, httpOptions);
    }

    /**
     * remove Stripe Account
     */
    public removeStripeAccount(): Observable<any> {
      let url = `${environment.orderApiUrl}/settings/payments/stripeConnect`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.delete(url, httpOptions);
    }

  /**
   * remove Stripe Account
   */
  public removePaypalAccount(): Observable<any> {
    let url = `${environment.orderApiUrl}/settings/payments/paypalConnect`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.delete(url, httpOptions);
  }

    /**
     * get store payments
     * @param storeID store id
     */
    public getStorePayments(): Observable<any> {
      let url = `${environment.orderApiUrl}/settings/payments`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.get(url, httpOptions);
    }

    /**
     * get store settings
     * @param data data
     */
    public updateStorePayments(data: StorePaymentsSettings): Observable<any> {
      let url = `${environment.orderApiUrl}/settings/payments`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.put(url, data, httpOptions);
    }

    /**
     * get plans
     * @param data data
     */
    public getSellerPlans(): Observable<any> {
      let url = `${environment.storeApiUrl}/billings/seller-plans`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.get(url, httpOptions);
    }

    /**
     * get plans
     * @param data data
     */
    public getPlans(): Observable<any> {
      let url = `${environment.storeApiUrl}/billings/plan`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.get(url, httpOptions);
    }

    public getPlansRefData(): Observable<any> {
      let url = `${environment.publicStoreApiUrl}/plans`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.get(url, httpOptions);
    }

    /**
     * get store settings
     * @param planId id
     * @param planType Type
     */
    public getPlanForCreation(planId: string, planType: string): Observable<any> {
      let url = `${environment.storeApiUrl}/billings/plan/creation/${planId}/${planType}`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.get(url, httpOptions);
    }

    /**
     * get store settings
     * @param planId id
     * @param planType Type
     */
    public getPlan(planId: string, planType: string): Observable<any> {
      let url = `${environment.storeApiUrl}/billings/plan/${planId}/${planType}`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.get(url, httpOptions);
    }

    /**
     * get Payment Infos of seller
     */
    public getPaymentInfos(): Observable<any> {
      let url = `${environment.storeApiUrl}/billings/payment-infos`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.get(url, httpOptions);
    }

    /**
     * get billing discount
     * @param code code
     * @param planType planType
     */
    public getBillingDiscount(code: string, planType: PlanInvoiceType, planId: string): Observable<any> {
      let url = `${environment.storeApiUrl}/billings/discount/${planId}/${planType}/${code?.replace(/\//g, '')}`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.get(url, httpOptions);
    }

    /**
     * get store settings
     * @param data data
     */
    public storeCreationCheckout(data: PlanCheckout): Observable<any> {
      let url = `${environment.storeApiUrl}/billings/store-creation-checkout`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.post(url, data, httpOptions);
    }

    /**
     * get store settings
     * @param data data
     */
    public billingCheckout(data: PlanCheckout): Observable<any> {
      let url = `${environment.storeApiUrl}/billings/checkout`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.post(url, data, httpOptions);
    }

    /**
     * get store settings
     * @param data data
     */
    public billingUpgradeCheckout(data: PlanCheckout): Observable<any> {
      let url = `${environment.storeApiUrl}/billings/upgrade-checkout`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.post(url, data, httpOptions);
    }

    /**
     * accept checkout
     * @param sessionId session id
     * @param update update
     */
    public acceptCheckout(sessionId: string, update: boolean): Observable<any> {
      let url = `${environment.storeApiUrl}/billings/${update ? 'accept-update-payment-method-checkout' : 'accept-checkout'}?sessionId=${sessionId}`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.post(url, null, httpOptions);
    }

  /**
   * accept checkout
   * @param sessionId session id
   */
  public cancelCheckout(): Observable<any> {
    let url = `${environment.storeApiUrl}/billings/cancel-checkout`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.delete(url, httpOptions);
  }

    /**
     * Cancel subscription
     */
    public cancelSubscription(reason: string): Observable<any> {
      let url = `${environment.storeApiUrl}/billings/cancel`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.put(url, reason, httpOptions);
    }

    /**
     * get store subscription
     * @param code code
     */
    public getStoreSubscription(): Observable<any> {
      let url = `${environment.storeApiUrl}/billings/subscription`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.get(url, httpOptions);
    }

    /**
     * get store subscription for checkout
     * @param code code
     */
    public getSubscriptionForCheckout(): Observable<any> {
      let url = `${environment.storeApiUrl}/billings/subscription-checkout`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.get(url, httpOptions);
    }

    /**
     * checkout update payment method
     */
    public updatePaymentCheckout(): Observable<any> {
      let url = `${environment.storeApiUrl}/billings/update-payment-method-checkout`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.post(url, null, httpOptions);
    }

    /**
     * get invoices
     * @param pageable
     */
    public getInvoices(pageable: Pageable): Observable<any> {

      let url = `${environment.storeApiUrl}/billings/invoices`;
      let params = pageable ? pageable.getParams() : new HttpParams();

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        params: params
      };

      return this.http.get(url, httpOptions);
    }

    /**
     * Download invoice
     * @param id
     */
    public downloadInvoice(id: string): Observable<any> {
      let url = `${environment.storeApiUrl}/billings/invoices/${id}`;
      return this.http.get(url, {
        observe: 'response',
        responseType: 'blob'
      });
    }

    getCurrencyLangData(): Observable<any>  {
      let url = `${environment.storeApiUrl}/settings/currency-lang`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.get(url, httpOptions);
    }


    setCurrencyLangData(data: StoreCurrencyLang): Observable<any> {
      let url = `${environment.storeApiUrl}/settings/currency-lang`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.put(url, data, httpOptions);
    }

    getSeoMetaData() {
      let url = `${environment.storeApiUrl}/settings/seo`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.get(url, httpOptions);
    }

    setSeoMetaData(data: StoreSeo) {
      let url = `${environment.storeApiUrl}/settings/seo`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.put(url, data, httpOptions);
    }

  retryBillingPayment() {
    let url = `${environment.storeApiUrl}/billings/pay-now`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, null, httpOptions);
  }
}

