import {Product} from "@app/_shared";

export abstract class AbstractLandingPage {
  id: string;
  name: string;
  available: boolean;
  seoTitle: string;
  seoDescription: string;
}

export class LandingPageForm extends AbstractLandingPage {
  product: string;
}

export class LandingPage extends AbstractLandingPage {
  slug: string;
  product: Product;
  createDate: Date;
}


