import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router'
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular'

@Injectable({providedIn: 'root'})
export class WelcomeAuthGuard extends KeycloakAuthGuard {

  constructor(protected router: Router, protected keycloakService: KeycloakService) {
    super(router, keycloakService)
  }


  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this.authenticated) {
      this.router.navigate(['/my-sites']).then();
      return false;
    }
    return true;

  }
}
