import {VariationOptionType} from "@app/_shared";

export class ProductOption {
  id: string;
  type: VariationOptionType;
  name: string;
  optionValues: ProductOptionValue[];
}

export class ProductOptionValue {
  color?: string;
  media?: string;
  value: string;
}
