import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {DatePipe} from "@angular/common";
import {DashboardFilter, OrderPeriodType} from "@app/_shared/model/stats/dashboard-filter.model";

@Injectable({providedIn: 'root'})
export class StatsService {

  constructor(private http: HttpClient, private datePipe: DatePipe) {
  }

  getFilterParams(filter?: DashboardFilter): HttpParams {

    let params = new HttpParams();

    if (filter?.periodType) {
      params = params.append('periodType', filter.periodType);
    }
    if (filter?.product) {
      params = params.append('product', filter.product);
    }

    if (filter?.startDate) {
      params = params.append('startDate', this.datePipe.transform(filter.startDate, "dd/MM/yyyy")!);
    }

    if (filter?.endDate) {
      params = params.append('endDate', this.datePipe.transform(filter.endDate, "dd/MM/yyyy")!);
    }

    return params;
  }

  /**
   * get visits by country
   */
  public getVisitsByCountry(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/visits-country`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by country
   */
  public getLiveOrdersByCountry(): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/orders-country`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ignoreError': 'true'
      })
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get Top visits by country
   */
  public getTopVisitsByCountry(): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/top-location`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ignoreError': 'true'
      })
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get Top visits by country
   */
  public getPagesView(): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/page-view`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ignoreError': 'true'
      })
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by os
   */
  public getVisitsByOs(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/visits-os`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by device
   */
  public getVisitsByDevice(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/visits-device`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by interval
   */
  public getVisitsByDate(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/visits-date`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by interval
   */
  public getVisitsAnalytics(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/analytics-visits`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by country
   */
  public getLiveVisits(): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/live-view`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ignoreError': 'true'
      })
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by country
   */
  public getTodayVisits(): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/today-visits`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ignoreError': 'true'
      })
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get orders by interval
   */
  public getOrdersByDate(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/orders-date`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get AVERAGE ORDERS PER DAY
   */
  public getAvgOrdersByDay(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/orders-avg-day`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get AVERAGE ORDERS PER HOUR
   */
  public getAvgOrdersByHour(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/orders-avg-hour`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get orders analytics
   */
  public getOrdersAnalytics(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/analytics-orders`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get orders by payment mode
   */
  public getOrdersByPayment(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/orders-payments`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }


  /**
   * get visits by payment mode
   */
  public getOrdersByPaymentAndDate(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/orders-payments-date`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by status
   */
  public getOrdersByStatus(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/orders-status`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get visits by interval
   */
  public getSalesByDate(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/sales-date`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get orders analytics
   */
  public getSalesAnalytics(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/analytics-sales`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get Inscriptions by date
   */
  public getInscriptionsByDate(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/inscriptions-date`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * get Top Customers
   */
  public getTopCustomers(filter: DashboardFilter): Observable<any> {
    let url = `${environment.storeApiUrl}/stats/top-customers`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  public getTopProducts(filter: DashboardFilter): Observable<any> {
    let url = `${environment.orderApiUrl}/stats/top-products`;
    let params = this.getFilterParams(filter)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  public getOrderGraphData(periodType: OrderPeriodType): Observable<any> {
    let url = `${environment.orderApiUrl}/orders/stats/${periodType}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

}

