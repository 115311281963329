import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {CommonService, YanLoaderService, YanToastrService} from "@app/_shared";
import {environment} from "../../environments/environment";
import {StoreCacheService} from "@app/_shared/service/store-cache.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-googlesheet-oauth2',
  templateUrl: './googlesheet-oauth2.component.html',
  styleUrls: ['./googlesheet-oauth2.component.scss']
})
export class GooglesheetOauth2Component implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private yanLoaderService: YanLoaderService,
    private yanToastrService: YanToastrService,
    private translate: TranslateService,
    private storeCacheService: StoreCacheService,
    private commonService: CommonService) {
  }

  ngOnInit(): void {
    this.yanLoaderService.startLoader('authorization-spinner');
    const sub = this.route.queryParams.subscribe(params => {
      const storeId = params.state;
      const code = params.code;
      if (code) {
        const url = `${environment.storeApiUrl}/google-sheet/link-account?code=${code}`;
        this.subscription.add(this.commonService.getWithStoreId(url, storeId)
          .subscribe({
            next: response => {
              this.yanToastrService.success(this.translate.instant('STORE_APP.SETTINGS.GOOGLE_SHEET.SUCCESSFULLY_ADD'));
              this.storeCacheService.getStoreCacheData(storeId).subscribe(data => {
                this.storeCacheService.initStoreCacheData(data);
                this.yanLoaderService.stopLoader('main-spinner');
                this.router.navigateByUrl(`/store/${storeId}/applications/setting/google-sheet`).then();
              });
            },
            error: error => {
              this.router.navigateByUrl('/error/not-found').then();
            }
          }));
      } else {
        this.router.navigateByUrl('/error/not-found').then();
      }

    });

    this.subscription.add(sub);
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
