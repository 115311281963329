import {Product} from "@app/_shared";

export abstract class AbstractBrand {

  id: string;
  name: string;
  description: string;
  media: string;

}

export class BrandForm extends AbstractBrand {
  products: string[];
}

export class Brand extends AbstractBrand {
  products: Product[];
}


