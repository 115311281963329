import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-no-result',
  templateUrl: './no-result.component.html',
  styleUrls: ['./no-result.component.scss']
})
export class NoResultComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  subTitle: string;

  show = false;

  constructor(private translate: TranslateService) {
  }

  ngOnInit(): void {
    if(!this.title){
      this.title = this.translate.instant('COMMON.NO_RESULT.NO_RESULT');
    }

    if(!this.subTitle){
      this.subTitle = this.translate.instant('COMMON.NO_RESULT.SUBTITLE');
    }

    setTimeout(() => {
      this.show = true;
    }, 200);
  }

}
