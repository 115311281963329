export enum MenuTargetType {
  PRODUCT = 'Product',
  COLLECTION = 'Collection',
  PAGE = 'Page',
  EXTERNAL = 'External link',
  SUB_MENU = 'Sub menu'
}

export const menuTargetTypes: any[] = Object.keys(MenuTargetType).map(item => {
  return {
    key: item,
    value: Object.values(MenuTargetType)[Object.keys(MenuTargetType).indexOf(item as unknown as MenuTargetType)]
  }
});

