import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {StoreCacheService} from "@app/_shared/service/store-cache.service";
import {FeatureAction} from "@app/_shared/enumeration/feature-action.enum";

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective {

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private storeCacheService: StoreCacheService,
    private viewContainer: ViewContainerRef
  ) {
  }

  @Input()
  set hasPermission(val: any) {
    if(this.hasAccess(val)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private hasAccess(val: Array<String>) {
    return !val || val.length === 0 || !this.storeCacheService.employee || (!this.storeCacheService.storePermissions.disabled && this.storeCacheService.storePermissions.confirmed
      && this.storeCacheService.storePermissions.features.filter(value =>  val[0].includes(value.feature) && value.actionFeatures.includes(val[1] as FeatureAction)).length > 0);
  }

}
